import get from "lodash.get";

export function getStatusCreditPayment(data: any) {
  if (data?.errors?.length > 0) {
    return "Error";
  }
  if (data?.canceled) {
    return "Canceled";
  }
  return (
    get(data, "init-santanderse.status") ||
    get(data, "init-resursfi.status", "InProgress")
  );
}
