import { getCountryCode } from "../../utils/countryNameToCode";
import { CreditProcessState } from "../../types/creditProcess/state";
import { Translation } from "../../types/taskProps";
import { Reason } from "../../types/creditProcess/state";
import { RowHeaders } from "../../types/rowHeaders";
import { classNames } from "../../utils/classNames";
import { getOverviewHelper } from "../../utils/overviewData";
import { Header } from "../tasks/components/Header";
import { OverviewTable } from "../tasks/components/manualHandling/OverviewTable";

export function CreditProcessCaseSummary({
  flowData,
  fixedT
}: {
  flowData: CreditProcessState;
  fixedT: Translation;
}) {
  const t = fixedT;
  const { country } = flowData?.applicant;
  const countryCode = getCountryCode(country);
  const overviewData = getOverviewHelper(flowData, countryCode);
  const { rejectionReasons } = flowData?.decisions?.creditScore ?? {};
  const { red, yellow } = rejectionReasons ?? {};

  const mainApplicantHeaders: RowHeaders[] = [
    { key: "name", value: t("name") },
    { key: "identity", value: t("identity") },
    { key: "phone", value: t("phone") },
    { key: "email", value: t("email") }
  ];
  const companyHeaders: RowHeaders[] = [
    { key: "name", value: t("name") },
    { key: "organizationNumber", value: t("organizationNumber") },
    { key: "shareCapital", value: t("shareCapital") }
  ];
  const decisionHeaders: RowHeaders[] = [
    { key: "approved-credit", value: t("approved-credit") },
    { key: "credit-desired", value: t("credit-desired") },
    { key: "max-approved-credit", value: t("max-approved-credit") },
    { key: "existing-credit-limit", value: t("existing-credit-limit") },
    { key: "remaining-credit-limit", value: t("remaining-credit-limit") }
  ];

  return (
    <div className="overflow-auto max-w-5xl flex flex-col gap-10">
      {((red && red.length > 0) || (yellow && yellow.length > 0)) && (
        <div className="grid grid-cols-1 gap-2">
          <Header header={t("rejection-reasons")} />
          <div>{displayReasons("red", red!)}</div>
          <div>{displayReasons("yellow", yellow!)}</div>
        </div>
      )}

      <div className="grid grid-cols-2 gap-x-10 gap-y-5 w-full mx-auto">
        <div className="flex flex-col flex-wrap justify-start items-start">
          <div className="flex flex-col gap-10">
            <OverviewTable
              headersList={mainApplicantHeaders}
              overview={overviewData!.mainApplicantData}
              tableHeader={t("mainApplicant")}
            />
            <OverviewTable
              headersList={companyHeaders}
              overview={overviewData!.companyData}
              tableHeader={t("company")}
            />
          </div>
        </div>

        {/* If desired credit is undefined then don't show info about credit at all (check the state of the flow)*/}
        <div className="flex flex-col flex-wrap justify-start items-end">
          <OverviewTable
            headersList={decisionHeaders}
            overview={overviewData!.decisionData}
            tableHeader={t("credits")}
          />
        </div>
      </div>
    </div>
  );
}

export function displayReasons(color: string, reasons: Reason[]) {
  return (
    <div>
      <div className="flex gap-2">
        {reasons.map((reason, index) => {
          return (
            <span
              key={`${reason}-${index}`}
              className={classNames(
                `border-2 rounded-lg px-4 py-1 flex flex-col`,
                color === "yellow"
                  ? "bg-yellow-100 border-yellow-500"
                  : "bg-red-100 border-red-500"
              )}
            >
              <span
                className={classNames(
                  color === "yellow" ? "text-yellow-700" : "text-red-700"
                )}
              >
                {reason.reason}
              </span>
              <span
                className={classNames(
                  "text-xs",
                  color === "yellow" ? "text-yellow-500" : "text-red-500"
                )}
              >
                {reason.reasonCode}
              </span>
            </span>
          );
        })}
      </div>
    </div>
  );
}
