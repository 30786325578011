import { FinancialTableDataItem } from "../types/normalizedData";

export function sortFinancialDataDescending(
  data: FinancialTableDataItem[] | undefined
): FinancialTableDataItem[] {
  if (!data) {
    return [];
  }
  return data.sort((a, b) => {
    if (typeof a.key === "number" && typeof b.key === "number") {
      return b.key - a.key;
    } else {
      return 0;
    }
  });
}
