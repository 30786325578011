import { COUNTRY_CODE, CountryCode } from "../types/creditProcess/enums";
import { CreditProcessState } from "../types/creditProcess/state";
import { B2BResponseModel } from "../types/d&b/b2b";
import { PaymentRemark } from "../types/normalizedData";
import { formatCurrency } from "./formatCurrency";
import { getCurrencyInfo } from "./getCurrencyInfo";

export function getPaymentRemarksHelper(
  data: CreditProcessState,
  countryCode: CountryCode | undefined
) {
  switch (countryCode) {
    case COUNTRY_CODE.SWEDEN:
      return getHistoricalPaymentRemarksSweden(data);
    case COUNTRY_CODE.NORWAY:
      return getHistoricalPaymentRemarksNorway(data);
    case COUNTRY_CODE.FINLAND:
      return getHistoricalPaymentRemarksFinland(data);
    case COUNTRY_CODE.DENMARK:
      return getHistoricalPaymentRemarksDenmark(data);
    default:
      return undefined;
  }
}

function getHistoricalPaymentRemarksSweden(
  data: CreditProcessState
): PaymentRemark[] {
  const { risk } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.SWEDEN
    >) ?? {};
  const { paymentRemarks } = risk?.paymentRemarks ?? {};
  return (
    paymentRemarks?.map((itm) => {
      const { amount: amountData, date, description } = itm ?? {};
      const { currency: currencyData, amount } = amountData ?? "";
      const { currency, symbol } = getCurrencyInfo[currencyData];
      const { year, month, day } = date ?? {};

      function formatCurrencyHelper(number: string | number) {
        return formatCurrency(number, symbol, currency);
      }
      const preFormatDate = new Date(year, month, day);
      return {
        date: preFormatDate,
        formattedDate: new Intl.DateTimeFormat("se-SV", {
          year: "numeric",
          month: "long",
          day: "numeric"
        }).format(preFormatDate),
        value: {
          amount: formatCurrencyHelper(amount),
          description: description ?? ""
        }
      };
    }) ?? []
  );
}

/**
 * Retrieves historical payment remarks for Norway. Would originally return an
 * array of PaymentRemarks within an array of PaymentRemarkGroups sorted
 * chronologically in descending order, but .flat() is used to flatten the array.
 * @param data - The B2B response model containing payment remarks data.
 * @returns An array of payment remarks objects.
 */
function getHistoricalPaymentRemarksNorway(
  data: CreditProcessState
): PaymentRemark[] {
  const { risk } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.NORWAY
    >) ?? {};
  const { paymentRemarksGroups } = risk?.paymentRemarks ?? {};

  return (
    paymentRemarksGroups
      ?.map((itm) => {
        const { paymentRemarks } = itm ?? {};
        return paymentRemarks?.map((itm) => {
          const { amount: amountData, date, type } = itm ?? {};
          const { currency: currencyData, amount } =
            amountData?.monetaryAmount ?? "";
          const { currency, symbol } = getCurrencyInfo[currencyData];
          const { year, month, day } = date ?? {};

          function formatCurrencyHelper(number: string | number) {
            return formatCurrency(number, symbol, currency);
          }
          const preFormatDate = new Date(year, month, day);
          return {
            date: preFormatDate,
            formattedDate: new Intl.DateTimeFormat("se-SV", {
              year: "numeric",
              month: "long",
              day: "numeric"
            }).format(preFormatDate),
            value: {
              amount: formatCurrencyHelper(amount),
              description: type ?? ""
            }
          };
        });
      })
      .flat() ?? []
  );
}

function getHistoricalPaymentRemarksDenmark(
  data: CreditProcessState
): PaymentRemark[] {
  const { risk } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.DENMARK
    >) ?? {};
  const { paymentRemarks } = risk?.paymentRemarks ?? {};
  return (
    paymentRemarks?.map((itm) => {
      const { amount: amountData, registrationDate, description } = itm ?? {};
      const { currency: currencyData, amount } = amountData ?? "";
      const { currency, symbol } = getCurrencyInfo[currencyData];
      const { year, month, day } = registrationDate ?? {};

      function formatCurrencyHelper(number: string | number) {
        return formatCurrency(number, symbol, currency);
      }
      const preFormatDate = new Date(year, month, day);
      return {
        date: preFormatDate,
        formattedDate: new Intl.DateTimeFormat("se-SV", {
          year: "numeric",
          month: "long",
          day: "numeric"
        }).format(preFormatDate),
        value: {
          amount: formatCurrencyHelper(amount),
          description: description ?? ""
        }
      };
    }) ?? []
  );
}

function getHistoricalPaymentRemarksFinland(
  data: CreditProcessState
): PaymentRemark[] {
  const { risk } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.FINLAND
    >) ?? {};
  const { paymentRemarks } = risk?.paymentRemarks ?? {};
  return (
    paymentRemarks?.map((itm) => {
      const { amount: amountData, date, description } = itm ?? {};
      const { currency: currencyData, amount } = amountData ?? "";
      const { currency, symbol } = getCurrencyInfo[currencyData];
      const { year, month, day } = date ?? {};

      function formatCurrencyHelper(number: string | number) {
        return formatCurrency(number, symbol, currency);
      }
      const preFormatDate = new Date(year, month, day);
      return {
        date: preFormatDate,
        formattedDate: new Intl.DateTimeFormat("se-SV", {
          year: "numeric",
          month: "long",
          day: "numeric"
        }).format(preFormatDate),
        value: {
          amount: formatCurrencyHelper(amount),
          description: description ?? ""
        }
      };
    }) ?? []
  );
}
