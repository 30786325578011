import { getNamespaceAndEnv } from "./namespace";

export function getToken() {
  const { namespace, env } = getNamespaceAndEnv();
  const session =
    sessionStorage.getItem(
      `oidc.user:https://oidc.${namespace}.elkjop-finance.stacc.${env}:portal`
    ) || "{}";
  return JSON.parse(session)?.access_token || "";
}
