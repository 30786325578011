import { ArrowIcon } from "../icons/Arrow";

type WidgetCardProps = {
  imgSrc?: string;
  text?: string;
  url?: string;
  isLast?: boolean;
  alt?: string;
};

export const WidgetCard = ({
  imgSrc,
  text = "",
  isLast,
  url,
  alt
}: WidgetCardProps) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="relative transform transition ease-in-out hover:scale-105 hover:shadow-2xl text-white shadow-lg rounded-md flex flex-col justify-end  "
    >
      <div className="absolute blackToTransparentGradient rounded-md top-0 left-0 w-full h-full"></div>
      <img
        height={250}
        width={250}
        alt={alt}
        className="rounded-md "
        src={imgSrc}
      ></img>
      <div className="flex absolute bottom-2 left-0 px-4 w-full flex-row justify-between items-center gap-6">
        <span className="font-medium">{text}</span>
        <ArrowIcon />
      </div>
    </a>
  );
};
