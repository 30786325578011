import { CreditPaymentQueueCard } from "./CreditPaymentQueueCard";
import { TaskProps } from "../types/taskProps";
import { CreditProcessQueueCard } from "./CreditProcessQueueCard";

export type QueueItemProps = {
  path: string;
  flow: TaskProps["flow"];
};

export function QueueItem({ path, flow }: QueueItemProps) {
  const { flowDefinitionId } = flow;

  switch (flowDefinitionId) {
    case "credit-payment":
      return (
        <div>
          <CreditPaymentQueueCard path={path} flow={flow} />
        </div>
      );

    case "credit-process":
      return (
        <div>
          <CreditProcessQueueCard path={path} flow={flow} />
        </div>
      );
  }

  return <div>Add when needed</div>;
}
