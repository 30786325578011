import { useRef, useEffect, useState } from "react";
import DmnViewer from "dmn-js";

import "dmn-js/dist/assets/diagram-js.css";
import "dmn-js/dist/assets/dmn-js-shared.css";
import "dmn-js/dist/assets/dmn-js-drd.css";
import "dmn-js/dist/assets/dmn-js-decision-table.css";
import "dmn-js/dist/assets/dmn-js-decision-table-controls.css";
import "dmn-js/dist/assets/dmn-js-literal-expression.css";
import "dmn-js/dist/assets/dmn-font/css/dmn.css";
import "./dmnOverrides.css";

type DMNViewerProps = {
  dmnXML: string;
  ruleIds: Array<string>;
};

type ViewerType = "drd" | "decisionTable" | "literalExpression";

export function DMNViewer({ dmnXML, ruleIds }: DMNViewerProps) {
  const dmnContainerRef = useRef(null);
  const [activeView, setActiveView] = useState<{
    id: string;
    type: ViewerType;
  }>({
    id: "",
    type: "drd"
  });

  useEffect(() => {
    const viewer = new DmnViewer({
      container: dmnContainerRef.current,
      keyboard: {
        bindTo: document
      }
    });

    async function loadXML() {
      try {
        await viewer.importXML(dmnXML);
        viewer.getActiveViewer().get("canvas").zoom("fit-viewport");

        viewer.on("views.changed", (event: any) => {
          setActiveView(event?.activeView);
        });
      } catch (err) {
        console.error("error rendering", err);
      }
    }
    loadXML();

    return () => {
      viewer.destroy();
    };
  }, [dmnXML]);

  const highlightRow = (id: string) => {
    const canvasCollection = document.getElementsByClassName("tjs-table");

    for (let i = 0; i < canvasCollection.length; i++) {
      const td = canvasCollection[i].querySelector(
        `[data-row-id="${id}"]`
      ) as HTMLElement;
      if (td) {
        const tr = td.parentNode as HTMLElement;
        if (tr) {
          tr.style.backgroundColor = "#52C761";
        }
      }
    }
  };

  useEffect(() => {
    if (activeView?.type === "decisionTable") {
      ruleIds.forEach((rule) => {
        highlightRow(rule);
      });
    }
  }, [activeView, ruleIds]);

  return <div ref={dmnContainerRef} className="w-full mx-auto" />;
}
