import { FormProvider, UseFormReturn } from "react-hook-form";
import { PrimaryButton } from "@flow/buttons";
import { useFormConnect } from "../../hooks/useFormConnect";
import { Number, Date, Select } from "@flow/forms";
import { TestCaseForm } from "./index";

export function DecisionTestingStartForm({
  methods,
  label,
  count = true,
  onSubmit
}: {
  methods: UseFormReturn<TestCaseForm>;
  label?: string;
  count?: boolean;
  onSubmit: any;
}) {
  return (
    <div>
      <div className="w-full">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-2">
              <h3>{label}</h3>
              <div className="flex flex-col md:flex-row items-start md:items-end justify-between gap-5">
                <div className="flex flex-col md:flex-row gap-5 md:items-end">
                  <DatePicker id="start" label="Start" />
                  <DatePicker id="end" label="End" />
                  <CountryChooser id="country" label="Country" />
                  {count ? <Count id="count" label="Number of hits" /> : null}
                </div>
              </div>
              <div className="items-start">
                <PrimaryButton type="submit">{"Submit"}</PrimaryButton>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

function DatePicker({ id, label }: { id: string; label: string }) {
  const { ref, error, value, ...props } = useFormConnect(id);
  return (
    <div>
      <Date {...props} label={label} />
      {error && <span className="block text-red-500">{error}</span>}
    </div>
  );
}
function CountryChooser({ id, label }: { id: string; label: string }) {
  const { ref, error, ...props } = useFormConnect(id);
  return (
    <div>
      <Select
        blankOptionLabel="Choose country..."
        {...props}
        label={label}
        options={[
          {
            label: "Norway",
            value: "norway"
          },
          {
            label: "Sweden",
            value: "sweden"
          },
          {
            label: "Denmark",
            value: "denmark"
          },
          {
            label: "Finland",
            value: "finland"
          }
        ]}
        required
        showBlankOption
      />
      {error && <span className="block text-red-500">{error}</span>}
    </div>
  );
}

function Count({ id, label }: { id: string; label: string }) {
  const { ref, error, value, ...props } = useFormConnect(id);
  const valueCheck = value ? parseInt(value) : undefined;
  return (
    <div>
      <Number {...props} defaultValue={valueCheck} label={label} min={0} />
      {error && <span className="block text-red-500">{error}</span>}
    </div>
  );
}
