function getNamespace() {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  return parts?.[1] || "dev-rein";
}

function getEnvironment(namespace: string) {
  return ["prod", "staging"].includes(namespace) ? "live" : "dev";
}

export function getNamespaceAndEnv() {
  const namespace = getNamespace();
  const env = getEnvironment(namespace);
  return { namespace, env };
}
