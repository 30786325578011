import {
  isAdmin,
  isCaseworkerFiB2c,
  isCaseworkerSeB2c,
  isCaseworkerFiB2b,
  isCaseworkerSeB2b,
  isCaseworkerNoB2b,
  isCaseworkerDkB2b
} from "../functions/isRole";
import type { TypeRole } from "../types/role";
import type { Queues } from "../types/queue";
import {
  FINANCIAL_PROVIDERS,
  FinancialProviders
} from "../types/financialProviders";
import { COUNTRY } from "../types/creditProcess/enums";

function queueDefinitionsByBankId(bankId: FinancialProviders) {
  const queues: Queues = {};
  if (bankId === FINANCIAL_PROVIDERS.SANTANDERSE) {
    queues[`approved-cases-queue-${bankId}`] = {
      name: `approved-cases-queue-${bankId}`,
      label: "approved-cases-label",
      queueFilter: `?status=active&hasIncidents=false&data.bankId=${bankId}&data.init-${bankId}.status=Approved&data.sign-${bankId}.signing.status=!Completed`,
      queueSort: "sort=createdAt&dir=-1",
      pollingInterval: 5000,
      limit: 10
    };
    queues[`signed-cases-queue-${bankId}`] = {
      name: `signed-cases-queue-${bankId}`,
      label: "signed-cases-label",
      queueFilter: `?status=active&hasIncidents=false&data.bankId=${bankId}&data.sign-${bankId}.signing.status=Completed`,
      queueSort: "sort=createdAt&dir=-1",
      pollingInterval: 5000,
      limit: 10
    };
    queues[`cancelled-cases-queue-${bankId}`] = {
      name: `cancelled-cases-queue-${bankId}`,
      label: "cancelled-cases-label",
      queueFilter: `?status=completed&data.bankId=${bankId}&data.canceled=true`,
      queueSort: "sort=createdAt&dir=-1",
      pollingInterval: 5000,
      limit: 10
    };
  }
  if (bankId === FINANCIAL_PROVIDERS.RESURSFI) {
    queues[`approved-cases-queue-${bankId}`] = {
      name: `approved-cases-queue-${bankId}`,
      label: "approved-cases-label",
      queueFilter: `?status=active&hasIncidents=false&data.bankId=${bankId}&data.init-${bankId}.status=Approved`,
      queueSort: "sort=createdAt&dir=-1",
      pollingInterval: 5000,
      limit: 10
    };
    queues[`cancelled-cases-queue-${bankId}`] = {
      name: `cancelled-cases-queue-${bankId}`,
      label: "cancelled-cases-label",
      queueFilter: `?data.bankId=${bankId}&data.init-${bankId}.status=Cancelled`,
      queueSort: "sort=createdAt&dir=-1",
      pollingInterval: 5000,
      limit: 10
    };
    queues[`rejected-cases-queue-${bankId}`] = {
      name: `rejected-cases-queue-${bankId}`,
      label: "rejected-cases-label",
      queueFilter: `?data.bankId=${bankId}&data.init-${bankId}.payment.updated.status=REJECTED`,
      queueSort: "sort=createdAt&dir=-1",
      pollingInterval: 5000,
      limit: 10
    };
  }
  queues[`active-cases-queue-${bankId}`] = {
    name: `active-cases-queue-${bankId}`,
    label: "active-cases-label",
    queueFilter: `?status=active&hasIncidents=false&data.bankId=${bankId}`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  queues[`completed-cases-queue-${bankId}`] = {
    name: `completed-cases-queue-${bankId}`,
    label: "completed-cases-label",
    queueFilter: `?hasIncidents=false&data.bankId=${bankId}&data.storage.stored.saveState=STORED`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  queues[`failed-cases-queue-${bankId}`] = {
    name: `failed-cases-queue-${bankId}`,
    label: "failed-cases-label",
    queueFilter: `?status=active&hasIncidents=true&data.bankId=${bankId}`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  queues[`captured-cases-queue-${bankId}`] = {
    name: `captured-cases-queue-${bankId}`,
    label: "captured-cases-label",
    queueFilter: `?hasIncidents=false&data.bankId=${bankId}&data.purchase.capture.0=`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };

  return queues;
}

function queueDefinitionsByCountry(country: string) {
  const queues: Queues = {};
  queues[`manual-handling-queue-${country}`] = {
    name: `manual-handling-queue-${country}`,
    label: "manual-handling",
    queueFilter: `?status=active&hasIncidents=false&data.applicant.country=${country}&data.businessStatus=MANUAL_HANDLING`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  queues[`error-queue-${country}`] = {
    name: `error-queue-${country}`,
    label: "error-queue",
    queueFilter: `?status=active&hasIncidents=true&data.applicant.country=${country}`,
    queueSort: "sort=createdAt&dir=-1",
    pollingInterval: 5000,
    limit: 10
  };
  return queues;
}

export function queueDefinition(role: TypeRole) {
  if (isCaseworkerSeB2c(role)) {
    return { ...queueDefinitionsByBankId(FINANCIAL_PROVIDERS.SANTANDERSE) };
  }
  if (isCaseworkerFiB2c(role)) {
    return { ...queueDefinitionsByBankId(FINANCIAL_PROVIDERS.RESURSFI) };
  }
  if (isCaseworkerNoB2b(role)) {
    return { ...queueDefinitionsByCountry(COUNTRY.NORWAY) };
  }
  if (isCaseworkerFiB2b(role)) {
    return { ...queueDefinitionsByCountry(COUNTRY.FINLAND) };
  }
  if (isCaseworkerSeB2b(role)) {
    return { ...queueDefinitionsByCountry(COUNTRY.SWEDEN) };
  }
  if (isCaseworkerDkB2b(role)) {
    return { ...queueDefinitionsByCountry(COUNTRY.DENMARK) };
  }
  if (isAdmin(role)) {
    return {
      ...queueDefinitionsByBankId(FINANCIAL_PROVIDERS.SANTANDERSE),
      ...queueDefinitionsByBankId(FINANCIAL_PROVIDERS.RESURSFI),
      ...queueDefinitionsByCountry(COUNTRY.NORWAY),
      ...queueDefinitionsByCountry(COUNTRY.FINLAND),
      ...queueDefinitionsByCountry(COUNTRY.DENMARK),
      ...queueDefinitionsByCountry(COUNTRY.SWEDEN)
    };
  }
  return {};
}

export function menuStructure(role: TypeRole) {
  const santanderseMenuItem = {
    name: "Santander SE B2C",
    elems: [
      "active-cases-queue-santanderse",
      "completed-cases-queue-santanderse",
      "cancelled-cases-queue-santanderse",
      "failed-cases-queue-santanderse",
      "approved-cases-queue-santanderse",
      "signed-cases-queue-santanderse",
      "captured-cases-queue-santanderse"
    ],
    isDefaultOpen: true
  };
  const resursfiMenuItem = {
    name: "Resurs FI B2C",
    elems: [
      "active-cases-queue-resursfi",
      "completed-cases-queue-resursfi",
      "cancelled-cases-queue-resursfi",
      "rejected-cases-queue-resursfi",
      "failed-cases-queue-resursfi",
      "approved-cases-queue-resursfi",
      "captured-cases-queue-resursfi"
    ],
    isDefaultOpen: true
  };
  const norwayMenuItem = {
    name: "Norway",
    elems: ["manual-handling-queue-norway", "error-queue-norway"],
    isDefaultOpen: true
  };
  const finlandMenuItem = {
    name: "Finland",
    elems: ["manual-handling-queue-finland", "error-queue-finland"],
    isDefaultOpen: true
  };
  const swedenMenuItem = {
    name: "Sweden",
    elems: ["manual-handling-queue-sweden", "error-queue-sweden"],
    isDefaultOpen: true
  };
  const denmarkMenuItem = {
    name: "Denmark",
    elems: ["manual-handling-queue-denmark", "error-queue-denmark"],
    isDefaultOpen: true
  };

  if (isCaseworkerSeB2c(role)) {
    return [santanderseMenuItem];
  }
  if (isCaseworkerFiB2c(role)) {
    return [resursfiMenuItem];
  }
  if (isCaseworkerNoB2b(role)) {
    return [norwayMenuItem];
  }
  if (isCaseworkerFiB2b(role)) {
    return [finlandMenuItem];
  }
  if (isCaseworkerDkB2b(role)) {
    return [denmarkMenuItem];
  }
  if (isCaseworkerSeB2b(role)) {
    return [swedenMenuItem];
  }
  if (isAdmin(role)) {
    return [
      santanderseMenuItem,
      resursfiMenuItem,
      norwayMenuItem,
      finlandMenuItem,
      swedenMenuItem,
      denmarkMenuItem
    ];
  }
  return [];
}
