import React from "react";
import { cn } from "../utils/cn";

interface HeaderProps {
  rounded?: boolean;
}

function Header({
  children,
  className,
  rounded = true,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement> & HeaderProps) {
  return (
    <div
      {...props}
      className={cn(
        "flex space-x-3 items-center text-base font-semibold px-5 h-12",
        rounded ? "rounded-t-md" : "",
        className ? className : "bg-caution-100"
      )}
    >
      {children}
    </div>
  );
}

function HeaderTitle({
  children,
  ...props
}: React.HtmlHTMLAttributes<HTMLHeadingElement>) {
  return <h2 {...props}>{children}</h2>;
}

function Content({
  children,
  className,
  horizontalPadding = true,
  verticalPadding = true,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement> & {
  horizontalPadding?: boolean;
  verticalPadding?: boolean;
}) {
  return (
    <div
      {...props}
      className={cn(
        verticalPadding ? "py-4 pb-10" : "",
        horizontalPadding ? "px-5" : "",
        className
      )}
    >
      {children}
    </div>
  );
}

export function Card({
  children,
  ...props
}: React.HtmlHTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props} className="h-fit-content rounded-md bg-white shadow">
      {children}
    </div>
  );
}

Card.Header = Header;
Card.HeaderTitle = HeaderTitle;
Card.Content = Content;
