import { Card } from "../Card";
import { TypeModifications } from "../../types/modification";
import { Icons } from "@flow/icons";

interface ModificationProps {
  modifications: TypeModifications["capture"];
}

export function ModificationsCard({ modifications }: ModificationProps) {
  const columns = (modifications?.length ?? 0) > 1 ? 2 : 1;

  return (
    <Card>
      <Card.Header className="flex space-x-3 items-center text-base font-semibold px-5 h-12 rounded-t-md bg-sea-light">
        <Card.HeaderTitle>
          Modifications: {modifications?.length}
        </Card.HeaderTitle>
      </Card.Header>
      <Card.Content className={`grid grid-cols-1 md:grid-cols-${columns}`}>
        {modifications?.map((modification, index) => (
          <div
            key={index}
            className={`flex flex-col mb-4 m-4 relative ${
              index === modifications.length - 1 &&
              modifications.length % 2 !== 0
                ? ""
                : "border-b-2 border-gray-300 md-4"
            } ${
              index === modifications.length - 1 &&
              modifications.length % 2 !== 0 &&
              columns === 2
                ? "md:col-span-2"
                : ""
            }`}
          >
            {index > 0 && columns === 1 && <hr className="my-4" />}
            <div className="flex items-center justify-between">
              <h1 className="text-lg font-bold text-primary-900 mb-2">
                Modification {index + 1}
              </h1>
              <div className="flex items-center">
                {modification.status === "USED" && <Icons.SuccessCircle />}
                {modification.status === "NOT_USED" && <Icons.ProgressActive />}
                {modification.status === "ERROR" && <Icons.ErrorCircle />}
              </div>
            </div>
            <div className="flex justify-between">
              <span className="text-lg font-bold text-primary-900">Type</span>
              <span className="text-primary-800">{modification.type}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-lg font-bold text-primary-900">Amount</span>
              <span className="text-primary-800">{modification.amount}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-lg font-bold text-primary-900">Status</span>
              <span className="text-primary-800">{modification.status}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-lg font-bold text-primary-900">
                Unique Id
              </span>
              <span className="text-primary-800">
                {modification.uniqueRequestId}
              </span>
            </div>
            <div className="flex justify-between mb-4">
              <span className="text-lg font-bold text-primary-900">
                Trans Ref
              </span>
              <span className="text-primary-800">
                {modification.merchantTransactionReference}
              </span>
            </div>
          </div>
        ))}
      </Card.Content>
    </Card>
  );
}
