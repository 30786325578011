import { CaseSummary } from "../components/CaseSummary";
import { QueueItem } from "../components/QueueItem";
import { menuStructure, queueDefinition } from "./queues";
import { getStatus } from "../functions/getStatus";
import { TypeRole } from "../types/role";
import { tabs } from "./tabs";
import { CustomComponent } from "../types/customComponent";
import { ManualHandling } from "../components/tasks/ManualHandling";
import { TaskProps } from "../types/taskProps";
import { SanctionsHandling } from "../components/tasks/SanctionsHandling";

export function customComponents(role: TypeRole): CustomComponent {
  return {
    case: {
      caseSummary: {
        component: CaseSummary,
        caseHistoryTasksLimit: 10000
      },
      businessStatus: {
        map: (flow: TaskProps["flow"]) => getStatus(flow)
      },
      tabs,
      tasks: {
        taskComponents: {
          "manual-handling": {
            component: ManualHandling,
            titleMap: () => "Manual Handling",
            size: "large"
          },
          "four-eyes-verification": {
            component: ManualHandling,
            titleMap: () => "Manual Handling Elevated",
            size: "large"
          },
          "compliance-check": {
            component: SanctionsHandling,
            titleMap: () => "Sanctions",
            size: "large"
          }
        }
      }
    },
    queue: {
      queueItem: QueueItem
    },
    menu: {
      menuStructure: menuStructure(role)
    },
    queueDefinition: queueDefinition(role)
  };
}
