import { MODIFICATION_STATUS } from "../../types/modification";
import { useQuery } from "@tanstack/react-query";
import { getModifications } from "../../utils/modifications";
import { Translation } from "../../types/taskProps";
import { StorageLink } from "./StorageLink";

type ModificationsProps = {
  flowId: string;
  fixedT: Translation;
  pspPaymentId: string;
};

export function Modifications({
  flowId,
  pspPaymentId,
  fixedT
}: ModificationsProps) {
  const { data } = useQuery({
    queryKey: ["modification", flowId],
    queryFn: () => getModifications(flowId)
  });
  const allModifications = [
    ...(data?.capture || []),
    ...(data?.refunds || []),
    ...(data?.cancel || [])
  ];
  const usedModifications = allModifications.filter(
    (modification) => modification.status === MODIFICATION_STATUS.USED
  );

  return (
    <div className="flex flex-col gap-3">
      {usedModifications.length ? (
        <h2 className="font-semibold pl-2 text-lg">
          {fixedT("modifications")}
        </h2>
      ) : null}
      {usedModifications.length ? (
        <div className="grid w-full auto-rows-auto bg-white px-2 py-1 rounded-md divide-y gap-1">
          {usedModifications.map(({ amount, type, modificationFlowId, id }) => {
            const hasFlowId = !!modificationFlowId;
            return (
              <div key={id} className={`flex divide-x items-center`}>
                <div
                  className={"flex-grow grid p-2 gap-2 divide-x grid-cols-2"}
                >
                  <div className={`flex items-center`}>
                    <span className="flex-shrink font-semibold justify-start text-left">
                      Type:
                    </span>
                    <span className="flex-grow justify-end text-right">
                      {type || "--"}
                    </span>
                  </div>
                  <div className={`flex items-center pl-2`}>
                    <span className="flex-shrink font-semibold justify-start text-left">
                      {`${fixedT("amount")}:`}
                    </span>
                    <span className="flex-grow justify-end text-right">
                      {amount || "--"}
                    </span>
                  </div>
                </div>
                {hasFlowId ? (
                  <div className={"flex-shrink"}>
                    <div className="pl-2 text-blue-400 hover:underline">
                      <a
                        className=""
                        href={`${
                          window.location.origin
                        }/cases/case/${type.toLowerCase()}/${modificationFlowId}`}
                      >
                        {fixedT("modification")}
                      </a>
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
      {allModifications.length && pspPaymentId ? (
        <StorageLink pspPaymentId={pspPaymentId} fixedT={fixedT} />
      ) : null}
    </div>
  );
}
