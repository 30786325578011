import { RadioButtons, Number, TextArea } from "@flow/forms";
import { PrimaryButton, SecondaryButton } from "@flow/buttons";
import { useForm, FormProvider } from "react-hook-form";
import { TaskProps } from "../../../../types/taskProps";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFormConnect } from "../../../../hooks/useFormConnect";
import { z } from "zod";
import { Header } from "../Header";

interface DecisionForm {
  outcome: boolean;
  approvedAmount: number;
  note: string;
}

export function Decision({ props }: { props: TaskProps }) {
  const { t, complete, save, task } = props;
  const { approvedAmount: savedApproved, note: savedNote } = task.data ?? {};

  const labels = {
    creditLimit: t("credit-limit"),
    decision: t("decision"),
    notes: t("notes"),
    submit: t("submit"),
    save: t("save")
  };
  const methods = useForm<DecisionForm>({
    defaultValues: {
      approvedAmount: savedApproved ?? task.defaults.defaultApprovedAmount ?? 0,
      note: savedNote ?? ""
    },
    resolver: zodResolver(
      z.object({
        approvedAmount: z.coerce
          .number()
          .min(1, "Approved amount must be greater than 0"),
        outcome: z.coerce.boolean(),
        note: z.string()
      })
    )
  });

  function onSubmit(data: DecisionForm) {
    complete(data);
  }

  function onSave(data: DecisionForm) {
    save(data);
  }

  const approvedIsDirty = methods.formState.dirtyFields.outcome;
  const noteOrLimitIsDirty =
    methods.formState.dirtyFields.note ||
    methods.formState.dirtyFields.approvedAmount;
  return (
    <div className="pt-20">
      <FormProvider {...methods}>
        <form>
          <div className="flex flex-col gap-5 ">
            <div className="flex flex-col gap-2">
              <Header header={labels.decision} />
              <RadioButtonsHandler id="outcome" />
            </div>

            <div className="flex flex-col gap-2">
              <Header header={labels.creditLimit} />
              <ApprovedAmount id="approvedAmount" />
            </div>
            <div className="flex flex-col gap-2">
              <Header header={labels.notes} />
              <Note id="note" />
            </div>
            <div className="flex gap-3">
              <PrimaryButton
                disabled={!approvedIsDirty}
                type="button"
                onClick={methods.handleSubmit(onSubmit)}
              >
                {labels.submit}
              </PrimaryButton>
              <SecondaryButton
                disabled={!noteOrLimitIsDirty}
                type="button"
                onClick={methods.handleSubmit(onSave)}
              >
                {labels.save}
              </SecondaryButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

function ApprovedAmount({ id }: { id: string }) {
  const { ref, error, value, ...props } = useFormConnect(id);
  return (
    <div className="max-w-xs">
      <Number {...props} defaultValue={parseInt(value)} />
      {error && <span className="block text-red-500">{error}</span>}
    </div>
  );
}

function Note({ id }: { id: string }) {
  const { ref, error, value, ...props } = useFormConnect(id);
  return (
    <div>
      {value ? (
        <TextArea
          className="max-w-xs"
          {...props}
          defaultValue={value}
          size="extraLarge"
        />
      ) : (
        <TextArea
          className="max-w-xs"
          {...props}
          placeholder="enter note..."
          size="extraLarge"
        />
      )}

      {error && <span className="block text-red-500">{error}</span>}
    </div>
  );
}

function RadioButtonsHandler({ id }: { id: string }) {
  const { ref, error, value, ...props } = useFormConnect(id);
  return (
    <div className="flex flex-col gap-2">
      <RadioButtons
        {...props}
        checkedValue={value}
        options={[
          { label: "Approve", value: true },
          { label: "Deny", value: false }
        ]}
      />
      {error && <span className="block text-red-500">{error}</span>}
    </div>
  );
}
