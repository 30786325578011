import { PROCESSES } from "../types/processes";
import { TaskProps } from "../types/taskProps";
import { getStatusCreditPayment } from "./getStatusCreditPayment";

export function getStatus(flow: TaskProps["flow"]) {
  const { data, _meta } = flow;
  const processDefinitionId = _meta?.processDefinitionId;
  switch (processDefinitionId) {
    case PROCESSES.CREDIT_PAYMENT:
      return getStatusCreditPayment(data);
    case PROCESSES.CREDIT_PROCESS:
      return data?.businessStatus;
    default:
      return flow?.status?.charAt(0).toUpperCase() + flow?.status?.slice(1);
  }
}
