import { countryCodeToCountryMap } from "../../../../types/creditProcess/countryMapper";
import {
  EntityMatch,
  EntityResponse
} from "../../../../types/creditProcess/opensanctions/response";
import { Header } from "../Header";
import { SanctionsDisplayTopics } from "./SanctionsDisplayTopics";
import { MultipleValuesRow } from "./MultipleValuesRow";
import { CountryCode } from "../../../../types/creditProcess/enums";
import { standarizeString } from "../../../../utils/standarizeString";
import { ButtonMoreInfo } from "../ButtonMoreInfo";
import { useFlowContext } from "../../../../hooks/useFlowContext";
import { NoRemarks } from "../NoRemarks";

export function SanctionsCompany({ data }: { data: EntityMatch }) {
  const { t } = useFlowContext();
  if (!data) {
    return <NoRemarks label={t("no-company-sanctions")} />;
  }

  const { match, entity } = data;
  const { topics } = match?.properties ?? [];
  const { id } = match ?? {};
  const { ownershipOwner } = entity?.properties ?? [];
  const name = match?.caption;

  const relationshipsData =
    ownershipOwner.length > 0 &&
    ownershipOwner.map((item: EntityResponse, index) => {
      const relationshipTopics =
        item?.properties?.asset?.[0]?.properties?.topics ?? [];
      return (
        <div className="py-1 flex flex-col gap-2" key={`asset-${index}`}>
          <h1 className="text-sea-sea font-medium">{`Asset ${index + 1}`}</h1>
          {relationshipTopics && (
            <SanctionsDisplayTopics topics={relationshipTopics} />
          )}
          <MultipleValuesRow
            data={[
              {
                name: "Name",
                value: item.properties?.asset?.[0].caption as string
              },
              {
                name: "Percentage held",
                value: item?.properties?.percentage?.[0] as string
              },
              {
                name: "Start date",
                value: item?.properties?.startDate?.[0] as string
              },
              {
                name: "End date",
                value: item?.properties?.endDate?.[0] as string
              },
              {
                name: "First seen",
                value: (item?.first_seen as string).split("T")?.[0]
              },
              {
                name: "Last changed",
                value: (item?.last_change as string).split("T")?.[0]
              }
            ]}
          />
        </div>
      );
    });

  return (
    <div className="flex flex-col gap-5">
      <div className="border-b text-xl">
        <Header header="Company" />
      </div>
      {topics && <SanctionsDisplayTopics topics={topics} />}
      <div className="flex flex-col gap-5">
        <MultipleValuesRow
          data={[
            { name: "Name", value: name },
            { name: "Type", value: entity?.schema },
            {
              name: "Registration number",
              value: entity?.properties?.registrationNumber?.[0] as string
            },
            {
              name: "Jurisdiction",
              value: standarizeString(
                countryCodeToCountryMap[
                  match?.properties?.jurisdiction?.[0] as CountryCode
                ]
              )
            },
            {
              name: "First seen",
              value: (match?.first_seen as string).split("T")?.[0]
            },
            {
              name: "Last changed",
              value: (match?.last_change as string).split("T")?.[0]
            }
          ]}
        />

        {relationshipsData && (
          <div>
            <div className="text-base">
              <Header header="Relationships" />
            </div>
            {relationshipsData}
          </div>
        )}
      </div>

      <ButtonMoreInfo url={`https://www.opensanctions.org/entities/${id}`} />
    </div>
  );
}
