import type { TypeSession } from "../../types/session";
import { Translation } from "../../types/taskProps";
import { CaseSummaryItem } from "./CaseSummaryItem";

type SessionProps = {
  session: TypeSession;
  fixedT: Translation;
};

export function Session({ session, fixedT }: SessionProps) {
  const salesPerson = session?.salesPerson;
  const fullName =
    salesPerson?.firstName && salesPerson?.lastName
      ? `${salesPerson.firstName} ${salesPerson.lastName}`
      : "";
  return (
    <div>
      <div className="px-1 mb-3 grid grid-cols-2 font-semibold text-lg">
        <h2>{fixedT("salesperson")}</h2>
        <h2 className="justify-end text-right">{fixedT("session")}</h2>
      </div>
      <div className="grid w-full auto-rows-auto gap-1 bg-white px-2 py-1 rounded-md">
        <CaseSummaryItem
          firstLabel={fixedT("full-name")}
          firstValue={fullName}
          secondLabel={fixedT("source-system")}
          secondValue={session?.sourceSystem}
        />
        <CaseSummaryItem
          firstLabel={fixedT("seller-id")}
          firstValue={salesPerson?.sellerId}
          secondLabel={fixedT("store-id")}
          secondValue={session?.storeId}
        />
      </div>
    </div>
  );
}
