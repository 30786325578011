import type { TypeNotification } from "../../types/notification";
import { Translation } from "../../types/taskProps";
import { CaseSummaryItem } from "./CaseSummaryItem";

type NotificationsCaseSummaryProps = {
  flowData: {
    notification: TypeNotification;
    creditPaymentFlowId: string;
  };
  fixedT: Translation;
};

export function NotificationsCaseSummary({
  flowData,
  fixedT
}: NotificationsCaseSummaryProps) {
  const notification: TypeNotification = flowData?.notification;
  const applicationId = flowData?.creditPaymentFlowId;
  return (
    <div
      className={
        "border-line grid gap-4 rounded-md border-solid border-primary-200 bg-primary-50 p-4 shadow items-center break-words text-gray-700 font-medium text-sm"
      }
    >
      <div className="pl-2 text-base text-blue-400 hover:underline">
        <a
          href={`${window.location.origin}/cases/case/credit-payment/${applicationId}`}
        >
          {`${fixedT("application")}: ${applicationId}`}
        </a>
      </div>
      {notification ? (
        <div className="grid w-full auto-rows-auto gap-1 bg-white px-2 py-1 rounded-md">
          <CaseSummaryItem
            firstLabel={fixedT("application-flow-id")}
            firstValue={applicationId}
            secondLabel={fixedT("event-type")}
            secondValue={notification.EventType}
          />
          <CaseSummaryItem
            firstLabel={fixedT("psp-payment-id")}
            firstValue={notification.PspPaymentId}
            secondLabel={fixedT("merchant-transaction-reference")}
            secondValue={notification.MerchantTransactionReference}
          />
        </div>
      ) : null}
    </div>
  );
}
