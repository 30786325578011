import { PrimaryButton } from "@flow/buttons";
import { useFlowContext } from "../../../hooks/useFlowContext";

export function ButtonMoreInfo({ url }: { url: string }) {
  const { t } = useFlowContext();
  return (
    <div className="w-32">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="More info"
      >
        <PrimaryButton className="w-full">{t("more-info")}</PrimaryButton>
      </a>
    </div>
  );
}
