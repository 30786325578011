import { APPS, CaseManager } from "@flow/case-manager";
import "./globals.css";
import customTranslations from "./translations/index.json";
import { customComponents } from "./configuration/customComponents";
import { searchConfiguration } from "./configuration/search";
import { MainLogo } from "./components/MainLogo";
import { Role } from "./types/role";
import type { User } from "./types/user";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PROCESSES } from "./types/processes";
import { getCustomRoutes } from "./configuration/customRoutes";

const skin = {
  mainLogo: () => <MainLogo />
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  }
});

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CaseManager
        configuration={(user: User) => {
          const role = user?.profile?.role || Role.User;
          return {
            apps: [APPS.Inbox, APPS.Search, APPS.Process],
            inboxConfiguration: {
              startableProcesses: [
                PROCESSES.CLEANUP,
                PROCESSES.CREDIT_SCORE_B2B_NORWAY // TODO: ADD REST FOR CREDIT_SCORE_STARTS
              ],
              queuesPollingEnabled: false
            },
            skin,
            customComponents: customComponents(role),
            language: "en",
            translates: [
              {
                language: "no",
                translates: customTranslations.no
              },
              {
                language: "en",
                translates: customTranslations.en
              }
            ],
            customRoutes: getCustomRoutes(role),
            searchConfiguration
          };
        }}
      />
    </QueryClientProvider>
  );
}
