export function formatIdentityNumber(
  identityNumber: string | undefined
): string {
  if (!identityNumber) {
    return "";
  }
  const digitsOnly = identityNumber.replace(/\D/g, "");

  // Check if the input is empty or not a valid identity number
  // Currently only formats norwegian (6 + 5 digits)
  if (!digitsOnly || digitsOnly.length !== 11) {
    return identityNumber; // Return the original input if it's empty or not valid
  }

  const formattedIdentityNumber = digitsOnly.replace(/(\d{6})(\d+)/, "$1 $2");

  return formattedIdentityNumber;
}
