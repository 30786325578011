import { COUNTRY_CODE, CountryCode } from "../types/creditProcess/enums";
import { CreditProcessState } from "../types/creditProcess/state";
import { B2BResponseModel } from "../types/d&b/b2b";

export type ChartBarData = {
  name: string | number;
  totalOperatingIncome: string | number;
};

export function getChartBarDataHelper(
  data: CreditProcessState,
  countryCode: CountryCode | undefined
) {
  switch (countryCode) {
    case COUNTRY_CODE.SWEDEN:
      return getChartDataSweden(data);
    case COUNTRY_CODE.NORWAY:
      return getChartDataNorway(data);
    case COUNTRY_CODE.DENMARK:
      return getChartDataDenmark(data);
    case COUNTRY_CODE.FINLAND:
      return getChartDataFinland(data);
    default:
      return undefined;
  }
}

function getChartDataSweden(data: CreditProcessState): ChartBarData[] {
  const { finance } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.SWEDEN
    >) ?? {};
  const { financialStatements } = finance?.financialStatements ?? {};
  return (
    financialStatements?.map((itm) => {
      const { incomeStatement } = itm ?? {};

      return {
        name: itm?.closingPeriod?.year ?? "",
        totalOperatingIncome: incomeStatement.otherOperatingIncome ?? "" //+ incomeStatement.grossTurnover ??
        // "" //TODO: Find correct fields
      };
    }) ?? {}
  );
}

function getChartDataNorway(data: CreditProcessState): ChartBarData[] {
  const { finance } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.NORWAY
    >) ?? {};
  const { financialStatements } = finance?.financialStatements ?? {};

  return (
    financialStatements?.map((itm) => {
      const { incomeStatement } = itm ?? {};

      return {
        name: itm?.closingPeriod?.year ?? "",
        totalOperatingIncome:
          parseInt(incomeStatement.otherOperatingIncome) ?? "" //+ incomeStatement.grossTurnover ??
        // "" //TODO: Find correct fields
      };
    }) ?? {}
  );
}

function getChartDataDenmark(data: CreditProcessState): ChartBarData[] {
  const { finance } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.DENMARK
    >) ?? {};
  const { financialStatements } = finance?.financialStatements ?? {};

  return (
    financialStatements?.map((itm) => {
      const { incomeStatement } = itm ?? {};

      return {
        name: itm?.closingPeriod?.year ?? "",
        totalOperatingIncome: incomeStatement.otherOperatingIncome ?? "" //+ incomeStatement.grossTurnover ??
        // "" //TODO: Find correct fields
      };
    }) ?? {}
  );
}

function getChartDataFinland(data: CreditProcessState): ChartBarData[] {
  const { finance } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.FINLAND
    >) ?? {};
  const { financialStatements } = finance?.financialStatements ?? {};

  return (
    financialStatements?.map((itm) => {
      const { incomeStatement } = itm ?? {};

      return {
        name: itm?.closingPeriod?.year ?? "",
        totalOperatingIncome: incomeStatement.otherOperatingIncome ?? "" //+ incomeStatement.grossTurnover ??
        // "" //TODO: Find correct fields
      };
    }) ?? {}
  );
}
