export function dateToString(
  day?: number,
  month?: number,
  year?: number
): string {
  if (day === undefined || month === undefined || year === undefined) {
    return "";
  }

  const date = new Date(year, month - 1, day); // Adjust month to be zero-based
  return date
    .toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    })
    .replace(/\//g, ".");
}
