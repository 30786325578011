import {
  COUNTRY,
  COUNTRY_CODE,
  CountryCode
} from "../types/creditProcess/enums";

export function getCountryCode(countryName: string): CountryCode | undefined {
  const normalizedCountryName = countryName.toLowerCase();

  if (COUNTRY.NORWAY === normalizedCountryName) {
    return COUNTRY_CODE.NORWAY;
  } else if (COUNTRY.SWEDEN === normalizedCountryName) {
    return COUNTRY_CODE.SWEDEN;
  } else if (COUNTRY.DENMARK === normalizedCountryName) {
    return COUNTRY_CODE.DENMARK;
  } else if (COUNTRY.FINLAND === normalizedCountryName) {
    return COUNTRY_CODE.FINLAND;
  }

  return undefined;
}
