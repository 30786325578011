import { TaskProps } from "../../../../types/taskProps";
import { FinancialTableDataItem } from "../../../../types/normalizedData";
import { RowHeaders } from "../../../../types/rowHeaders";
import { sortFinancialDataDescending } from "../../../../utils/sortFinancialDataDescending";
import { getFinancialStatementsDataHelper } from "../../../../utils/financialHistoryData";
import { StickyHeader } from "../StickyHeader";
import { getCountryCode } from "../../../../utils/countryNameToCode";
import { classNames } from "../../../../utils/classNames";
import { getChartLineDataHelper } from "../../../../utils/chartLineData";
import { ChartLine } from "./ChartLine";
import { ChartBar } from "./ChartBar";
import { getChartBarDataHelper } from "../../../../utils/chartBarData";
import { NoRemarks } from "../NoRemarks";

export function FinancialStatements(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;
  const { country } = data.applicant;
  const { financialStatements } =
    data?.raw?.dunAndBradstreet?.companyRiskAndCreditData?.finance
      ?.financialStatements ?? {};

  if (!financialStatements || financialStatements?.length === 0) {
    return <NoRemarks label={t("no-financial-data")} isSuccess={false} />;
  }

  const countryCode = getCountryCode(country);

  const chartLineData = getChartLineDataHelper(data, countryCode);
  const chartBarData = getChartBarDataHelper(data, countryCode);
  const financialHistoryData = getFinancialStatementsDataHelper(
    data,
    countryCode
  );

  const sortedHistoricalFinancialData =
    sortFinancialDataDescending(financialHistoryData);
  const length = sortedHistoricalFinancialData.length;

  const rowHeaders: RowHeaders[] = [
    { key: "netIncome", value: t("netIncome") },
    { key: "cashInHand", value: t("cashInHand") },
    { key: "turnover", value: t("turnover") },
    { key: "resultBeforeTax", value: t("resultBeforeTax") },
    { key: "resultAfterDepreciation", value: t("resultAfterDepreciation") },
    { key: "totalFinancialIncome", value: t("totalFinancialIncome") },
    { key: "totalFinancialCosts", value: t("totalFinancialCosts") }
  ];
  const assetsHeaders: RowHeaders[] = [
    { key: "fixedAssets", value: t("fixedAssets") },
    { key: "intangibleAssets", value: t("intangibleAssets") },
    { key: "financialAssets", value: t("financialAssets") },
    { key: "otherFixedAssets", value: t("otherFixedAssets") },
    { key: "totalCurrentAssets", value: t("totalCurrentAssets") },
    { key: "inventories", value: t("inventories") },
    { key: "totalReceivables", value: t("totalReceivables") },
    { key: "investments", value: t("investments") },
    { key: "otherCurrentAssets", value: t("otherCurrentAssets") },
    { key: "totalAssets", value: t("totalAssets") }
  ];
  const liabilitiesAndEquityHeaders: RowHeaders[] = [
    { key: "shareholderEquity", value: t("shareholderEquity") },
    { key: "retainedEarnings", value: t("retainedEarnings") },
    { key: "totalLiabilities", value: t("totalLiabilities") },
    { key: "longTermLiabilities", value: t("longTermLiabilities") },
    { key: "otherLongTermLiabilities", value: t("otherLongTermLiabilities") },
    { key: "currentLiabilitiesTotal", value: t("currentLiabilitiesTotal") },
    { key: "totalEquityAndLiabilities", value: t("totalEquityAndLiabilities") }
  ];

  return (
    <div className="flex flex-col gap-10 pt-5 w-full h-full">
      <div className="grid grid-cols-2 h-60">
        <ChartBar charData={chartBarData!} />
        <ChartLine charData={chartLineData!} />
      </div>
      <div
        className={classNames(
          "overflow-auto pb-10",
          length > 2 ? "w-full" : "max-w-2xl"
        )}
      >
        <div className="-mb-10 z-20 sticky left-0">
          <StickyHeader header={t("income")} />
        </div>
        <table className=" table-fixed w-full h-full mb-10">
          <thead>
            <tr>
              <th className="sticky left-0 bg-white z-10 w-48"></th>
              {sortedHistoricalFinancialData.map((dataItem) => (
                <th
                  key={dataItem.key}
                  className="sticky top-0 px-2 py-3 bg-white text-right text-xs leading-4 font-medium text-gray-500  border-b border-gray-200 tracking-wider w-40"
                >
                  {dataItem.key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="border-b border-gray-200">
            {generateTableRows(rowHeaders, sortedHistoricalFinancialData)}
          </tbody>
        </table>

        <StickyHeader header={t("assets")} />
        <table className=" divide-y divide-gray-200 table-fixed w-full mb-10">
          <tbody className="border-b border-gray-200">
            {generateTableRows(assetsHeaders, sortedHistoricalFinancialData)}
          </tbody>
        </table>

        <StickyHeader header={t("liabilitiesAndEquity")} />
        <table className=" divide-y divide-gray-200 table-fixed w-full">
          <tbody className="border-b border-gray-200">
            {generateTableRows(
              liabilitiesAndEquityHeaders,
              sortedHistoricalFinancialData
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export const generateTableRows = (
  headers: RowHeaders[],
  financialData: FinancialTableDataItem[]
): JSX.Element[] => {
  return headers.map((header) => (
    <tr key={header.key} className="bg-white border-b border-gray-300">
      <td className="sticky left-0 z-10 bg-white py-2 font-medium text-gray-700 whitespace-nowrap w-48">
        {header.value}
      </td>
      {financialData.map((yearlyData, index) => {
        const cellValue = yearlyData.value[header.key] || "N/A";
        return (
          <td
            key={`${header.key}-${yearlyData.key}`}
            className="px-2 pb-1 pt-3 text-gray-600 text-right w-40"
          >
            {cellValue}
          </td>
        );
      })}
    </tr>
  ));
};
