import { CurrencyInfo } from "../types/currencyInfo";

export const getCurrencyInfo: CurrencyInfo = {
  NOK: { currency: "NOK", symbol: "no-NO" },
  SEK: { currency: "SEK", symbol: "sv-SE" },
  EUR: { currency: "EUR", symbol: "fi-FI" },
  DKK: { currency: "DKK", symbol: "da-DK" },
  "": {
    currency: "NOK",
    symbol: "no-NO"
  }
};
