import axios from "axios";
import { getToken } from "./sessionStorage";

export async function fetchDecisionsByHash(hash: string, filename: string) {
  const token = getToken();
  return axios
    .get<string>(`/api/decision-info/xmlFile/${hash}?filename=${filename}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => response.data);
}
