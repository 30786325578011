import { useState } from "react";
import { Icons } from "@flow/icons";
import { Colors } from "@flow/colors";
import { OverviewTableDataItem } from "../../../../types/normalizedData";
import { generateCellComment, generateCellWarning } from "./OverviewTable";
import { Translation } from "../../../../types/taskProps";
import { Header } from "../Header";

export function OverviewHistoricalCollapsable({
  overviewHistorical,
  t,
  tableHeader
}: {
  overviewHistorical: OverviewTableDataItem[];
  t: Translation;
  tableHeader?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const { value: currentScore, comment: currentDescription } =
    overviewHistorical[0];

  const generateTableRows = (
    overviewDataHistorical: OverviewTableDataItem[]
  ): JSX.Element[] => {
    return overviewDataHistorical.slice(1).map((historicalRating, index) => {
      const cellValue = historicalRating?.value || "N/A";
      const cellComment = historicalRating?.comment;
      const cellWarning = historicalRating?.warning;

      return (
        <tr
          key={`${historicalRating.key}-${index}`}
          className="bg-white border-b border-gray-300"
        >
          <td className="bg-white py-2 px-5 font-medium text-gray-700 w-48">
            {t(historicalRating.key as string)}
          </td>
          <td className="text-gray-600 text-right w-64">{cellValue}</td>
          {generateCellComment(cellComment)}
          {cellWarning ? generateCellWarning(cellWarning) : null}
        </tr>
      );
    });
  };

  return (
    <div>
      {tableHeader && <Header header={tableHeader} />}
      <div className="cursor-pointer">
        <table className="w-full">
          <tbody>
            <tr
              className="bg-white border-b border-gray-300 "
              onClick={toggleCollapse}
            >
              <td className=" bg-white py-2 font-medium text-gray-700 w-48">
                {t("credit-rating")}
              </td>
              <td className=" text-gray-600 text-right w-64">
                {currentScore || "N/A"}
              </td>
              {generateCellComment(currentDescription)}
              <td className="relative">
                <div className="absolute right-0 top-1.5">
                  {isOpen ? (
                    <Icons.ChevronUp color={Colors.Sea} />
                  ) : (
                    <Icons.ChevronDown color={Colors.Sea} />
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {isOpen && (
          <div className="">
            <table className="w-full table-auto">
              <tbody>
                {overviewHistorical
                  ? generateTableRows(overviewHistorical)
                  : null}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
