import CircleIcon from "../tasks/components/CircleIcon";

export function ApplicationsCountDisplay(
  setIsHovered: (hovered: boolean) => void,
  count: number,
  isHovered: boolean
) {
  return (
    <div
      className="relative group transition transform hover:scale-125"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div>
        <CircleIcon number={count} include0={true} />
      </div>
      {isHovered && (
        <div className="bg-white rounded-lg text-sm shadow absolute bottom-full left-1/2 transform -translate-x-1/2 p-4 z-10">
          <p className="text-blue-600">
            Total number of applications used for this test run.
          </p>
        </div>
      )}
    </div>
  );
}
