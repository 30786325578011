import { Link } from "react-router-dom";
import { QueueItemProps } from "./QueueItem";
import { format, parseISO } from "date-fns";
import { formatCurrency } from "../utils/formatCurrency";
import { getCurrencyInfo } from "../utils/getCurrencyInfo";
import { classNames } from "../utils/classNames";

export function CreditProcessQueueCard({ path, flow }: QueueItemProps) {
  const {
    flowId,
    flowDefinitionId,
    createdAt,
    referenceId,
    referenceName,
    data
  } = flow;
  const applicant = data?.applicant?.companyApplicant?.companyName;
  const desiredCredit = data?.application?.desiredCredit;
  const orgnr = data?.applicant?.companyApplicant?.companyRegistrationNumber;

  let displayDesiredCredit = "";
  if (desiredCredit) {
    const value = desiredCredit.value;
    const currency = desiredCredit.currency;
    const currencyInfo = getCurrencyInfo[currency];
    const { symbol } = currencyInfo;
    displayDesiredCredit = formatCurrency(value, symbol, currency);
  }

  const createdAtDate = format(parseISO(createdAt), "dd.MM.yyyy");

  const isActive =
    window?.location?.pathname ===
    `/inbox/${path}/${flowDefinitionId}/${flowId}`;

  return (
    <Link
      aria-current={"page"}
      to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
      className={classNames(
        "flex flex-col px-3 py-2 text-xs",
        isActive
          ? "border-l-4 border-primary-800 bg-primary-100"
          : "border-l-4 border-opacity-0 bg-primary-50 shadow"
      )}
    >
      <div>
        <span>
          {referenceName} - {referenceId}
        </span>
        <div className="py-2 text-base font-medium">{applicant}</div>
        <div className="flex flex-col gap-2">
          <div>
            Organization number: {orgnr ?? "N/A"}
            {displayDesiredCredit && (
              <div className="flex flex-row gap-2">
                <div>Desired credit:</div>
                <div className="text-black font-medium">
                  {displayDesiredCredit}
                </div>
              </div>
            )}
          </div>
          <div>{createdAtDate}</div>
        </div>
      </div>
    </Link>
  );
}
