import { TaskProps } from "../../../../types/taskProps";
import { NoRemarks } from "../NoRemarks";
import { SanctionsApplicant } from "../sanctions/SanctionsApplicant";
import { SanctionsCompany } from "../sanctions/SanctionsCompany";

export function Sanctions(props: TaskProps) {
  const { flow, t } = props;
  const { company, applicant } = flow?.data?.sanctions?.matches ?? {};

  if (!company && !applicant) {
    return <NoRemarks label={t("no-sanctions")} />;
  }
  return (
    <div className="p-10">
      <div className="flex flex-col gap-10 max-w-4xl">
        <SanctionsCompany data={company} />
        <SanctionsApplicant data={applicant} />
      </div>
    </div>
  );
}
