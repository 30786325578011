import { PROCESSES } from "../types/processes";
import { mapBankId } from "../functions/mapBankId";
import { getStatusCreditPayment } from "../functions/getStatusCreditPayment";
import { getStatus } from "../functions/getStatus";
import { concatAmount } from "../functions/concatAmount";

interface BaseSearchField {
  name: string;
  value: string;
  type?: "select" | "date" | "text";
  process?: string[];
}

interface TextSearchfield extends BaseSearchField {
  type: "text";
  queryOperator?: "equals" | "contains";
}

interface SelectSearchField extends BaseSearchField {
  type: "select";
  options: { label: string; value: string }[];
  multiple?: boolean;
}

interface DateSearchField extends BaseSearchField {
  type: "date";
  queryOperator: "gt" | "gte" | "lt" | "lte";
  min?: string;
  max?: string;
}

export type SearchField = TextSearchfield | SelectSearchField | DateSearchField;

interface SearchResultFields {
  name: string;
  value: string;
  process?: string[];
  mapping?: (value: any) => any;
}

const searchFields: SearchField[] = [
  { name: "Flow ID", value: "flowId", type: "text" },
  {
    name: "Scenario",
    value: "sandboxConfig.scenarioId",
    queryOperator: "contains",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Request ID",
    value: "data.requestId",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Bank Payment ID",
    value: "data.referenceId",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Basket ID",
    value: "data.application.order.basketId",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Elkjøp Reference",
    value: "data.session.merchantTransactionReference",
    queryOperator: "contains",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Resurs Order Reference",
    value: "data.init-resursfi.payment.start.order.orderReference",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Source System",
    value: "data.session.sourceSystem",
    type: "select",
    options: [
      { label: "Commerce", value: "COMMERCE" },
      {
        label: "Blueberry",
        value: "BLUEBERRY"
      }
    ],
    process: [PROCESSES.CREDIT_PAYMENT]
  },
  {
    name: "Bank",
    value: "data.application.financialProvider",
    type: "select",
    options: [
      {
        label: "Santander SE",
        value: "santanderse"
      },
      {
        label: "Resurs Bank FI",
        value: "resursfi"
      }
    ],
    process: [PROCESSES.CREDIT_PAYMENT]
  },
  {
    name: "Order Amount",
    value: "data.application.order.amount.value",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "National Identity",
    value: "data.applicant.applicants.0.identity",
    queryOperator: "contains",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Applicant Email",
    value: "data.applicant.applicants.0.email",
    process: [PROCESSES.CREDIT_PAYMENT],
    type: "text"
  },
  {
    name: "Application Flow ID",
    value: "data.creditPaymentFlowId",
    process: [PROCESSES.NOTIFICATIONS],
    type: "text"
  },
  {
    name: "Application Flow ID",
    value: "data.creditPayment.flowId",
    process: [PROCESSES.CAPTURE, PROCESSES.CANCEL, PROCESSES.REFUND],
    type: "text"
  },
  {
    name: "Elkjøp Reference",
    value: "data.merchantTransactionReference",
    process: [PROCESSES.CAPTURE, PROCESSES.CANCEL, PROCESSES.REFUND],
    type: "text"
  },
  {
    name: "Created from",
    value: "createdAt",
    min: "2023-09-14",
    max: new Date().toISOString().split("T")[0],
    type: "date",
    queryOperator: "gte"
  },
  {
    name: "Created before",
    value: "createdAt",
    type: "date",
    min: "2023-09-14",
    max: new Date().toISOString().split("T")[0],
    queryOperator: "lt"
  }
];

const searchResultFields: SearchResultFields[] = [
  { name: "Flow ID", value: "flowId" },
  {
    name: "Scenario",
    value: "sandboxConfig.scenarioId",
    process: [PROCESSES.CREDIT_PAYMENT]
  },
  {
    name: "Bank",
    value: "data.application.financialProvider",
    process: [PROCESSES.CREDIT_PAYMENT],
    mapping: (financialProvider) => mapBankId(financialProvider)
  },
  {
    name: "Status",
    value: "data",
    process: [PROCESSES.CREDIT_PAYMENT],
    mapping: (data) => getStatusCreditPayment(data)
  },
  {
    name: "Status",
    value: "data.businessStatus",
    process: [PROCESSES.CREDIT_PROCESS]
  },
  {
    name: "Country",
    value: "data.applicant.country",
    process: [PROCESSES.CREDIT_PROCESS],
    mapping: (country: string) =>
      country.charAt(0).toUpperCase() + country.slice(1)
  },
  {
    name: "Customer Type",
    value: "data.applicant.customerType",
    process: [PROCESSES.CREDIT_PROCESS],
    mapping: (customerType: string) => customerType.toUpperCase()
  },
  {
    name: "Source System",
    value: "data.session.sourceSystem",
    process: [PROCESSES.CREDIT_PAYMENT]
  },
  {
    name: "Application Flow ID",
    value: "data.creditPaymentFlowId",
    process: [PROCESSES.NOTIFICATIONS]
  },
  {
    name: "Event Type",
    value: "data.notification.EventType",
    process: [PROCESSES.NOTIFICATIONS]
  },
  {
    name: "Bank ID",
    value: "data.flowData.bankId",
    process: [PROCESSES.NOTIFICATIONS],
    mapping: (bankId) => mapBankId(bankId)
  },
  {
    name: "Application Flow ID",
    value: "data.creditPayment.flowId",
    process: [PROCESSES.CAPTURE, PROCESSES.CANCEL, PROCESSES.REFUND]
  },
  {
    name: "Amount",
    value: "data.amount",
    process: [PROCESSES.CAPTURE, PROCESSES.CANCEL, PROCESSES.REFUND],
    mapping: (amount) => concatAmount(amount)
  },
  {
    name: "Bank ID",
    value: "data.creditPayment.bankId",
    process: [PROCESSES.CAPTURE, PROCESSES.CANCEL, PROCESSES.REFUND],
    mapping: (bankId) => mapBankId(bankId)
  }
];

export const searchConfiguration = {
  search: {
    fields: searchFields
  },
  searchResults: {
    fields: searchResultFields,
    businessStatus: {
      map: (flow: any) => {
        return getStatus(flow);
      }
    }
  },
  searchProcesses: [
    PROCESSES.CREDIT_PAYMENT,
    PROCESSES.RECONCILIATION,
    PROCESSES.NOTIFICATIONS,
    PROCESSES.CLEANUP,
    PROCESSES.CREDIT_PROCESS,
    PROCESSES.CLEANUP,
    PROCESSES.CANCEL,
    PROCESSES.REFUND,
    PROCESSES.CAPTURE
  ],
  searchQueryView: "searchView"
};
