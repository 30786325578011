import React from "react";

interface CircleIconProps {
  number: number | undefined;
  include0?: boolean;
}

const CircleIcon: React.FC<CircleIconProps> = ({ number, include0 }) => {
  if ((number === 0 && !include0) || number === undefined) {
    return null;
  }
  return (
    <div className="relative inline-block">
      <div className=" min-w-5 w-auto h-5 px-1 bg-sea-light rounded-full flex items-center justify-center text-blue-600">
        {number}
      </div>
    </div>
  );
};

export default CircleIcon;
