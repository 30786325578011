import { useQuery } from "@tanstack/react-query";
import { getModificationsByPaymentId } from "../../utils/modifications";
import { useEffect, useState } from "react";
import { CenteredSpinner } from "@flow/icons";
import { SearchForm } from "./SearchForm";
import { OverviewCard } from "./OverviewCard";
import { ModificationsCard } from "./ModificationsCard";
import { EventsCard } from "./EventsCard";

export function Storage() {
  const [search, setSearch] = useState("");
  const [flowId, setFlowId] = useState("");

  const pspPaymentId =
    new URLSearchParams(window.location.search).get("pspPaymentId") || "";

  useEffect(() => {
    setSearch(pspPaymentId);
    setFlowId(pspPaymentId);
  }, [pspPaymentId]);

  const purchase = useQuery({
    queryKey: ["purchase", flowId],
    queryFn: () => getModificationsByPaymentId(flowId),
    enabled: !!flowId
  });

  const allModifications = [
    ...(purchase?.data?.capture || []),
    ...(purchase?.data?.refunds || []),
    ...(purchase?.data?.cancel || [])
  ];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setFlowId(search);
  };

  function Loading() {
    return <CenteredSpinner size={100} />;
  }

  function NoResults() {
    return (
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-2xl font-medium text-primary-900">
          No results found
        </h1>
      </div>
    );
  }

  return (
    <main className="flex flex-col w-full min-h-screen overflow-y-scroll flex-1 gap-4 p-12 md:gap-8">
      <div className="flex flex-row justify-center">
        <div className="flex flex-col flex-grow 2xl:max-w-7xl justify-center gap-10 p-8 bg-primary-searchBoxGray border border-gray-200">
          <div className="flex justify-center">
            <h1 className="font-medium text-3xl text-primary-ferrari">
              Storage
            </h1>
          </div>
          <div className="flex flex-row justify-start">
            <SearchForm
              onSearchChange={handleInputChange}
              onFormSubmit={handleFormSubmit}
              searchValue={search}
            />
          </div>
          {purchase.isFetching ? (
            <Loading />
          ) : purchase.data ? (
            <>
              <OverviewCard overview={purchase.data || []} />
              {allModifications.length > 0 && (
                <ModificationsCard modifications={allModifications} />
              )}
              {purchase.data.events && purchase.data.events.length > 0 && (
                <EventsCard events={purchase.data.events || []} />
              )}
            </>
          ) : purchase.data === "" ? (
            <NoResults />
          ) : null}
        </div>
      </div>
    </main>
  );
}
