import { ValueOf } from "../../utils/tsUtils";

export const COUNTRY = {
  NORWAY: "norway",
  DENMARK: "denmark",
  FINLAND: "finland",
  SWEDEN: "sweden"
} as const;

export type Country = (typeof COUNTRY)[keyof typeof COUNTRY];

export const COUNTRY_CODE = {
  NORWAY: "no",
  SWEDEN: "se",
  FINLAND: "fi",
  DENMARK: "dk"
} as const;

export type CountryCode = ValueOf<typeof COUNTRY_CODE>;

export const APPLICATION_STATUS = {
  IN_PROGRESS: "InProgress",
  APPROVED: "Approved",
  AWAITING_SIGNATURE: "AwaitingSignature",
  DECLINED: "Declined",
  MANUAL_REVIEW: "ManualReview",
  CANCELLED: "Cancelled",
  ERROR: "Error",
  COMPLETED: "Completed"
};
export type ApplicationStatus = ValueOf<typeof APPLICATION_STATUS>;

export const BUSINESS_STATUS = {
  CREATED: "CREATED",
  CREDIT_SCORE: "CREDIT_SCORE",
  AUTHENTICATION: "AUTHENTICATION",
  AUTHENTICATION_FAILED: "AUTHENTICATION_FAILED",
  AUTHENTICATION_COMPLETED: "AUTHENTICATION_COMPLETED",
  MANUAL_HANDLING: "MANUAL_HANDLING",
  CREDIT_SCORE_REJECTED: "CREDIT_SCORE_REJECTED",
  NO_DNB_COMPANY_DATA: "NO_DUN_AND_BRADSTREET_RECORDS",
  NO_DNB_PERSON_DATA: "NO_DNB_PERSON_DATA",
  APPROVE_CREDIT_DECISION: "APPROVE_CREDIT_DECISION",
  APPROVE_CREDIT_DECISION_DECLINED: "APPROVE_CREDIT_DECISION_DECLINED",
  MANUAL_HANDLING_REJECTED: "MANUAL_HANDLING_REJECTED",
  SELECT_INVOICING_OPTION: "SELECT_INVOICING_OPTION",
  SIGNING: "SIGNING",
  CHOOSE_SIGNERS: "CHOOSE_SIGNERS",
  NO_ROARING_RECORDS: "NO_ROARING_RECORDS",
  SIGNING_AUTHENTICATION: "SIGNING_AUTHENTICATION",
  SIGNING_AUTHENTICATION_FAILED: "SIGNING_AUTHENTICATION_FAILED",
  SIGN_DOCUMENTS: "SIGN_DOCUMENTS",
  SIGNING_FAILED: "SIGNING_FAILED",
  SIGNING_PACKAGING_FAILED: "SIGNING_PACKAGING_FAILED",
  SIGNING_RESET: "SIGNING_RESET",
  SIGNING_COMPLETED: "SIGNING_COMPLETED",
  COMPLETED_APPROVED: "COMPLETED_APPROVED",
  COMPLETED_REJECTED: "COMPLETED_REJECTED",
  CANCELLED: "CANCELLED",
  TIMED_OUT: "TIMED_OUT"
} as const;

export type BusinessStatus = ValueOf<typeof BUSINESS_STATUS>;

export const CUSTOMER_TYPE = {
  B2B: "b2b",
  B2C: "b2c"
} as const;

export type CustomerType = (typeof CUSTOMER_TYPE)[keyof typeof CUSTOMER_TYPE];
