import { ValueOf } from "../utils/tsUtils";

export const Role = {
  Admin: "admin",
  AdminB2c: "admin-b2c",
  CaseworkerSeB2c: "caseworker-se-b2c",
  CaseworkerNoB2c: "caseworker-no-b2c",
  CaseworkerFiB2c: "caseworker-fi-b2c",
  CaseworkerDkB2c: "caseworker-dk-b2c",
  CaseworkerReadOnlyB2C: "caseworker-readOnly-b2c",
  User: "user",
  AdminB2b: "admin-b2b",
  CaseworkerSeB2b: "caseworker-se-b2b",
  CaseworkerNoB2b: "caseworker-no-b2b",
  CaseworkerFiB2b: "caseworker-fi-b2b",
  CaseworkerDkB2b: "caseworker-dk-b2b",
} as const;

export type TypeRole = ValueOf<typeof Role>;
