import { TaskProps, Translation } from "../../../../types/taskProps";
import { getPaymentRemarksHelper } from "../../../../utils/paymentRemarksHistory";

import { PaymentRemark } from "../../../../types/normalizedData";
import { StickyHeader } from "../StickyHeader";
import { getCountryCode } from "../../../../utils/countryNameToCode";
import { NoRemarks } from "../NoRemarks";

export function PaymentRemarks(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;
  const { country } = data?.applicant;

  const countryCode = getCountryCode(country);
  const paymentRemarks = getPaymentRemarksHelper(data, countryCode);
  const hasPaymentRemarks = paymentRemarks && paymentRemarks.length > 0;
  return (
    <div className="overflow-auto">
      {hasPaymentRemarks && <StickyHeader header={t("paymentRemarks")} />}
      {hasPaymentRemarks ? (
        generatePaymentRemarksTable(paymentRemarks, t)
      ) : (
        <NoRemarks label={t("noPaymentRemarks")} />
      )}
    </div>
  );
}

function generatePaymentRemarksTable(
  paymentRemarks: PaymentRemark[],
  t: Translation
) {
  return (
    <div>
      <table className="mt-3 border-b border-gray-300 w-auto table-fixed ">
        <tbody>
          {paymentRemarks.map((remark, index) => (
            <tr
              key={`${remark.formattedDate}-${index}`}
              className="bg-white border-b border-gray-300 whitespace-nowrap"
            >
              <td className="sticky left-0 z-10 bg-white py-2 pr-2 font-medium text-gray-700 w-40">
                {remark.formattedDate}
              </td>
              <td className="pl-20 pr-4 text-gray-600 text-right w-56">
                {remark.value.amount}
              </td>
              <td className="px-1 text-left text-gray-400 w-full">
                {remark.value.description}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
