import { Role, TypeRole } from "../types/role";

export function isCaseworkerSeB2c(role: TypeRole) {
  return role === Role.CaseworkerSeB2c;
}

export function isCaseworkerFiB2c(role: TypeRole) {
  return role === Role.CaseworkerFiB2c;
}
export function isCaseworkerSeB2b(role: TypeRole) {
  return role === Role.CaseworkerSeB2b;
}

export function isCaseworkerFiB2b(role: TypeRole) {
  return role === Role.CaseworkerFiB2b;
}
export function isCaseworkerDkB2b(role: TypeRole) {
  return role === Role.CaseworkerSeB2b;
}

export function isCaseworkerNoB2b(role: TypeRole) {
  return role === Role.CaseworkerNoB2b;
}

export function isAdmin(role: TypeRole) {
  const adminRoles: TypeRole[] = [Role.Admin, Role.AdminB2c, Role.AdminB2b];
  return adminRoles.includes(role);
}
