export function formatCurrency(
  amount: string | number,
  symbol: string,
  currency: string
): string {
  if (amount == null) {
    return "";
  }

  let value;
  if (typeof amount === "string") {
    if (amount === "") {
      return "";
    }
    value = parseFloat(amount);
  } else {
    value = amount;
  }

  if (Number.isNaN(value)) {
    return "";
  }

  return new Intl.NumberFormat(symbol, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
    currencyDisplay: "code"
  }).format(value);
}
