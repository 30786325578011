import { Header } from "../Header";

type MultipleValueRowProps = {
  title?: string;
  data:
    | Array<{ name: string; value: string | string[] | number | undefined }>
    | undefined;
};

export function MultipleValuesRow({ title, data }: MultipleValueRowProps) {
  if (!data) {
    return null;
  }
  return (
    <div className="flex flex-col gap-1">
      {title && <Header header={title} />}
      <div>
        <table className="w-full table-auto">
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index} className="bg-white border-b border-gray-300">
                  <td className=" bg-white py-2 font-medium text-gray-700">
                    {item.name}
                  </td>
                  <td className="text-gray-600 text-right">
                    {item.value || "N/A"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
