import { TaskProps } from "../../types/taskProps";
import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import { Layout } from "./components/manualHandling/Layout";
import { DecisionPanel } from "./components/manualHandling/DecisionPanel";
import { FlowContext } from "../../hooks/useFlowContext";
import { FinancialStatements } from "./components/manualHandling/FinancialStatements";
import { PaymentRemarks } from "./components/manualHandling/PaymentRemarks";
import { Overview } from "./components/manualHandling/Overview";
import { Decision } from "./components/manualHandling/Decision";
import CircleIcon from "./components/CircleIcon";
import { getManualHandlingTabsHelper } from "../../utils/manualHandlingTabsInfo";
import { getCountryCode } from "../../utils/countryNameToCode";
import { classNames } from "../../utils/classNames";
import { Sanctions } from "./components/manualHandling/Sanctions";

export function ManualHandling(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;
  const { country } = data?.applicant;
  const countryCode = getCountryCode(country);
  const tabsCounters = getManualHandlingTabsHelper(data, countryCode);
  const tabs = [
    { key: "overview", value: t("overview") },
    { key: "financial-statements", value: t("financial-statements") },
    { key: "payment-remarks", value: t("payment-remarks") },
    { key: "sanctions", value: t("sanctions") }
  ];

  const getIcon = (tab: string) => {
    if (tab === "financial-statements") {
      return <CircleIcon number={tabsCounters?.financialStatementsLength} />;
    }
    if (tab === "payment-remarks") {
      return <CircleIcon number={tabsCounters?.paymentRemarksLength} />;
    }
    if (tab === "sanctions") {
      return <CircleIcon number={tabsCounters?.sanctionsCount} />;
    }
    return null;
  };

  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <div className="overflow-auto">
          <Tab.Group>
            <Tab.List className="sticky top-0 z-50 bg-white border-b  ">
              {tabs.map((tab, index) => (
                <Tab as={Fragment} key={index}>
                  {({ selected }) => (
                    <button
                      className={classNames(
                        "whitespace-nowrap border-b-2 border-transparent py-4 px-4 text-sm font-medium text-gray-500 hover:border-sea-light hover:text-sea-sea",
                        selected ? "border-sea-light text-sea-sea" : ""
                      )}
                    >
                      <div className="flex flex-row gap-1">
                        {tab.value}
                        {getIcon(tab.key)}
                      </div>
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="h-full bg-white-100 p-4">
              <Tab.Panel>
                <Overview {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <FinancialStatements {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <PaymentRemarks {...props} />
              </Tab.Panel>
              <Tab.Panel>
                <Sanctions {...props} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <div className="text-sm">
          <DecisionPanel>
            <Decision props={props} />
          </DecisionPanel>
        </div>
      </Layout>
    </FlowContext.Provider>
  );
}
