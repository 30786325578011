import { Error } from "../../types/errors";
import { Translation } from "../../types/taskProps";

type ErrorsProps = {
  flowData: {
    errors: Error[];
  };
  fixedT: Translation;
};
export function Errors({ flowData, fixedT }: ErrorsProps) {
  const errors = flowData?.errors;
  return (
    <section className="w-full py-4 flex flex-col gap-2">
      {errors?.map((error, index) => {
        return <ErrorObject key={index} {...error} />;
      })}
    </section>
  );
}

function ErrorObject({ code, detail, title, status, step }: Error) {
  return (
    <div
      className={`${
        status === 500 ? "bg-negative-600" : "bg-caution-700"
      } rounded-lg p-4 text-white relative`}
    >
      <div className="flex justify-between items-center">
        <div>
          <p className="font-mono">
            Status: <b>{status}</b>
          </p>
          <p className="font-mono">
            Code: <b>{code}</b>
          </p>
        </div>
        <p className="font-mono font-bold">{step}</p>
      </div>
      <h2 className="text-xl font-bold mt-2 border-b-2 border-white">
        {title}
      </h2>
      <p className="mt-2">{detail}</p>
    </div>
  );
}
