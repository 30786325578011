export const PROCESSES = {
  CREDIT_PAYMENT: "credit-payment",
  RECONCILIATION: "reconciliation",
  NOTIFICATIONS: "notifications",
  CLEANUP: "cleanup-process",
  CREDIT_PROCESS: "credit-process",
  CANCEL: "cancel",
  REFUND: "refund",
  CAPTURE: "capture",
  INIT_RESURSFI: "init-resursfi",
  INIT_SANTANDERSE: "init-santanderse",
  SIGN_RESURSFI: "sign-resursfi",
  SIGN_SANTANDERSE: "sign-santanderse",
  VALIDATE_NOTIFICATION_EVENT: "validate-notification-event",
  CREDIT_SCORE_B2B_NORWAY: "credit-score-b2b-norway",
  CREDIT_SCORE_B2C_NORWAY: "credit-score-b2c-norway",
  CREDIT_SCORE_B2B_SWEDEN: "credit-score-b2b-sweden",
  CREDIT_SCORE_B2C_SWEDEN: "credit-score-b2c-sweden",
  CREDIT_SCORE_B2B_FINLAND: "credit-score-b2b-finland",
  CREDIT_SCORE_B2C_FINLAND: "credit-score-b2c-finland",
  CREDIT_SCORE_B2B_DENMARK: "credit-score-b2b-denmark",
  CREDIT_SCORE_B2C_DENMARK: "credit-score-b2c-denmark"
} as const;
