import { Icons } from "@flow/icons";

export function NoRemarks({
  label,
  isSuccess = true
}: {
  label: string;
  isSuccess?: boolean;
}) {
  return (
    <div className="flex items-center justify-center mt-20 text-xl">
      {label}
      {isSuccess && <Icons.SuccessCircle />}
    </div>
  );
}
