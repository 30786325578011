import { COUNTRY_CODE, CountryCode } from "../types/creditProcess/enums";
import { CreditProcessState } from "../types/creditProcess/state";
import { B2BResponseModel } from "../types/d&b/b2b";
import { FinancialTableDataItem } from "../types/normalizedData";
import { formatCurrency } from "./formatCurrency";
import { getCurrencyInfo } from "./getCurrencyInfo";

export function getFinancialStatementsDataHelper(
  data: CreditProcessState,
  countryCode: CountryCode | undefined
) {
  switch (countryCode) {
    case COUNTRY_CODE.SWEDEN:
      return getHistoricalFinancialDataSweden(data);
    case COUNTRY_CODE.NORWAY:
      return getHistoricalFinancialDataNorway(data);
    case COUNTRY_CODE.DENMARK:
      return getHistoricalFinancialDataDenmark(data);
    case COUNTRY_CODE.FINLAND:
      return getHistoricalFinancialDataFinland(data);
    default:
      return undefined;
  }
}

function getHistoricalFinancialDataSweden(
  data: CreditProcessState
): FinancialTableDataItem[] {
  const { finance } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.SWEDEN
    >) ?? {};
  const { financialStatements } = finance?.financialStatements ?? {};
  return (
    financialStatements?.map((itm) => {
      const { currency: currencyData } = itm ?? "";
      const { currency, symbol } = getCurrencyInfo[currencyData];
      const { incomeStatement } = itm ?? {};
      const { currentAssets, nonCurrentAssets, assetsTotal } =
        itm?.balanceSheet?.assets ?? {};
      const { shareholdersEquity, liabilities, equityAndLiabilitiesTotal } =
        itm?.balanceSheet?.liabilitiesAndEquity ?? {};
      const { currentLiabilities, nonCurrentLiabilities } = liabilities ?? {};

      function formatCurrencyHelper(number: number | string) {
        return formatCurrency(number, symbol, currency);
      }

      return {
        key: itm?.closingPeriod?.year ?? "",
        value: {
          /* Income */
          netIncome: formatCurrencyHelper(incomeStatement?.netProfitLoss),
          cashInHand: formatCurrencyHelper(currentAssets?.cashBankTotal),
          turnover: formatCurrencyHelper(incomeStatement?.turnover),
          resultBeforeTax: formatCurrencyHelper(
            incomeStatement?.profitLossBeforeTax
          ),
          resultAfterDepreciation: formatCurrencyHelper(
            incomeStatement?.depreciation
          ),
          totalFinancialIncome: formatCurrencyHelper(
            incomeStatement?.financialIncomeTotal
          ),
          totalFinancialCosts: formatCurrencyHelper(
            incomeStatement?.financialCostsTotal
          ),

          /* Assets */
          fixedAssets: formatCurrencyHelper(
            nonCurrentAssets?.whereofNotDepreciableNonCurrentCosts
          ),
          intangibleAssets: formatCurrencyHelper(
            nonCurrentAssets?.intangibleAssetsTotal
          ),
          financialAssets: formatCurrencyHelper(
            nonCurrentAssets?.financialNonCurrentAssetsTotal
          ),
          otherFixedAssets: formatCurrencyHelper(
            nonCurrentAssets?.otherNonCurrentAssets
          ),
          totalCurrentAssets: formatCurrencyHelper(
            currentAssets?.currentAssetsTotal
          ),
          inventories: formatCurrencyHelper(currentAssets?.inventoriesTotal),
          totalReceivables: formatCurrencyHelper(
            currentAssets?.currentReceivablesTotal
          ),
          investments: formatCurrencyHelper(
            currentAssets?.currentInvestmentsTotal
          ),
          otherCurrentAssets: formatCurrencyHelper(
            currentAssets?.otherCurrentAssets
          ),
          totalAssets: formatCurrencyHelper(assetsTotal),

          /* Liabilities & equity */
          shareholderEquity: formatCurrencyHelper(
            shareholdersEquity?.equityTotal
          ),
          retainedEarnings: formatCurrencyHelper(
            shareholdersEquity?.retainedEarnings
          ),
          totalLiabilities:
            currentLiabilities?.currentLiabilitiesTotal == null ||
            nonCurrentLiabilities?.longTermLiabilitiesTotal == null
              ? ""
              : formatCurrencyHelper(
                  currentLiabilities?.currentLiabilitiesTotal +
                    nonCurrentLiabilities?.longTermLiabilitiesTotal
                ),
          longTermLiabilities: formatCurrencyHelper(
            nonCurrentLiabilities?.longTermLiabilitiesTotal
          ),
          otherLongTermLiabilities: formatCurrencyHelper(
            nonCurrentLiabilities?.otherLongTermLiabilities
          ),
          currentLiabilitiesTotal: formatCurrencyHelper(
            currentLiabilities.currentLiabilitiesTotal
          ),
          totalEquityAndLiabilities: formatCurrencyHelper(
            equityAndLiabilitiesTotal
          )
        }
      };
    }) ?? {}
  );
}

function getHistoricalFinancialDataNorway(
  data: CreditProcessState
): FinancialTableDataItem[] {
  const { finance } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.NORWAY
    >) ?? {};
  const { financialStatements } = finance?.financialStatements ?? {};
  return (
    financialStatements?.map((itm) => {
      const { currency: currencyData } = itm ?? "";
      const { currency, symbol } = getCurrencyInfo[currencyData];
      const { incomeStatement } = itm ?? {};
      const { currentAssets, nonCurrentAssets, assetsTotal } =
        itm?.balanceSheet?.assets ?? {};
      const { shareholdersEquity, liabilities, equityAndLiabilitiesTotal } =
        itm?.balanceSheet?.liabilitiesAndEquity ?? {};
      const { currentLiabilities, nonCurrentLiabilities } = liabilities ?? {};
      function formatCurrencyHelper(number: number | string) {
        return formatCurrency(number, symbol, currency);
      }

      return {
        key: itm?.closingPeriod?.year ?? "",
        value: {
          /* Income */
          netIncome: formatCurrencyHelper(
            incomeStatement?.profitLossAfterFinancialItems
          ),
          cashInHand: formatCurrencyHelper(currentAssets?.cashAndBankBalance),
          turnover: formatCurrencyHelper(incomeStatement?.grossTurnover),
          resultBeforeTax: formatCurrencyHelper(
            incomeStatement?.profitLossBeforeTax
          ),
          resultAfterDepreciation: formatCurrencyHelper(
            incomeStatement?.depreciation
          ),
          totalFinancialIncome: formatCurrencyHelper(
            incomeStatement?.financialIncomeTotal
          ),
          totalFinancialCosts: formatCurrencyHelper(
            incomeStatement?.financialCostsTotal
          ),

          /* Assets */
          fixedAssets: formatCurrencyHelper(nonCurrentAssets?.fixedAssetsTotal),
          intangibleAssets: formatCurrencyHelper(
            nonCurrentAssets?.intangibleAssetsTotal
          ),
          financialAssets: formatCurrencyHelper(
            nonCurrentAssets?.financialAssetsTotal
          ),
          otherFixedAssets: formatCurrencyHelper(
            nonCurrentAssets?.otherFixedAssets
          ),
          totalCurrentAssets: formatCurrencyHelper(
            currentAssets?.currentAssetsTotal
          ),
          inventories: formatCurrencyHelper(currentAssets?.inventories),
          totalReceivables: formatCurrencyHelper(
            currentAssets?.currentReceivablesTotal
          ),
          investments: formatCurrencyHelper(currentAssets?.investmentsTotal),
          otherCurrentAssets: formatCurrencyHelper(
            currentAssets?.investmentsOther
          ),
          totalAssets: formatCurrencyHelper(assetsTotal),

          /* Liabilities & equity */
          shareholderEquity: formatCurrencyHelper(shareholdersEquity?.equity),
          retainedEarnings: formatCurrencyHelper(
            shareholdersEquity?.retainedEarnings
          ),
          totalLiabilities: formatCurrencyHelper(liabilities?.liabilitiesTotal),
          longTermLiabilities: formatCurrencyHelper(
            nonCurrentLiabilities?.longTermLiabilitiesTotal
          ),
          otherLongTermLiabilities: formatCurrencyHelper(
            nonCurrentLiabilities?.otherLongTermLiabilities
          ),
          currentLiabilitiesTotal: formatCurrencyHelper(
            currentLiabilities?.shortTermLiabilitiesTotal
          ),
          totalEquityAndLiabilities: formatCurrencyHelper(
            equityAndLiabilitiesTotal
          )
        }
      };
    }) ?? {}
  );
}

function getHistoricalFinancialDataDenmark(
  data: CreditProcessState
): FinancialTableDataItem[] {
  const { finance } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.DENMARK
    >) ?? {};
  const { financialStatements } = finance?.financialStatements ?? {};
  return (
    financialStatements?.map((itm) => {
      const { currency: currencyData } = itm ?? "";
      const { currency, symbol } = getCurrencyInfo[currencyData];
      const { incomeStatement } = itm ?? {};
      const { currentAssets, nonCurrentAssets, assetsTotal } =
        itm?.balanceSheet?.assets ?? {};
      const { shareholdersEquity, liabilities, equityAndLiabilitiesTotal } =
        itm?.balanceSheet?.liabilitiesAndEquity ?? {};
      const { currentLiabilities, nonCurrentLiabilities } = liabilities ?? {};
      function formatCurrencyHelper(number: number | string) {
        return formatCurrency(number, symbol, currency);
      }

      return {
        key: itm?.closingPeriod?.year ?? "",
        value: {
          /* Income */
          netIncome: formatCurrencyHelper(incomeStatement?.annualResult),
          cashInHand: formatCurrencyHelper(
            currentAssets?.cashAndCashEquivalents
          ),
          turnover: formatCurrencyHelper(incomeStatement?.grossTurnover),
          resultBeforeTax: formatCurrencyHelper(
            incomeStatement?.profitLossBeforeTax
          ),
          resultAfterDepreciation: formatCurrencyHelper(
            incomeStatement?.depreciation
          ),
          totalFinancialIncome: formatCurrencyHelper(
            incomeStatement?.financialIncomeTotal
          ),
          totalFinancialCosts: formatCurrencyHelper(
            incomeStatement?.financialCostsTotal
          ),

          /* Assets */
          fixedAssets: formatCurrencyHelper(
            nonCurrentAssets?.nonCurrentAssetsTotal
          ),
          intangibleAssets: formatCurrencyHelper(
            nonCurrentAssets?.intangibleAssetsTotal
          ),
          financialAssets: formatCurrencyHelper(
            nonCurrentAssets?.financialNonCurrentAssetsTotal
          ),
          otherFixedAssets: formatCurrencyHelper(
            nonCurrentAssets?.otherFinancialAssets
          ),
          totalCurrentAssets: formatCurrencyHelper(
            currentAssets?.currentAssetsTotal
          ),
          inventories: formatCurrencyHelper(currentAssets?.inventories),
          totalReceivables:
            currentAssets?.accountsReceivable == null ||
            currentAssets?.receivableFromGroupAndAssociatedCompanies == null ||
            currentAssets?.otherReceivables == null
              ? ""
              : formatCurrencyHelper(
                  currentAssets?.accountsReceivable +
                    currentAssets?.receivableFromGroupAndAssociatedCompanies +
                    currentAssets?.otherReceivables
                ),

          investments: formatCurrencyHelper(
            currentAssets?.securities // TODO: Find correct field
          ),
          otherCurrentAssets: formatCurrencyHelper(
            currentAssets?.otherCurrentAssets
          ),
          totalAssets: formatCurrencyHelper(assetsTotal),

          /* Liabilities & equity */
          shareholderEquity: formatCurrencyHelper(
            shareholdersEquity?.equityTotal
          ),
          retainedEarnings: formatCurrencyHelper(
            shareholdersEquity?.transferredEarnings
          ),
          totalLiabilities:
            liabilities?.nonCurrentLiabilities?.longTermLiabilitiesTotal ==
              null ||
            liabilities?.currentLiabilities?.currentLiabilitiesTotal == null
              ? ""
              : formatCurrencyHelper(
                  liabilities?.nonCurrentLiabilities?.longTermLiabilitiesTotal +
                    liabilities?.currentLiabilities?.currentLiabilitiesTotal
                ),
          longTermLiabilities: formatCurrencyHelper(
            nonCurrentLiabilities?.longTermLiabilitiesTotal
          ),
          otherLongTermLiabilities: formatCurrencyHelper(
            nonCurrentLiabilities?.otherLongTermLiabilities
          ),
          currentLiabilitiesTotal: formatCurrencyHelper(
            currentLiabilities?.currentLiabilitiesTotal
          ),
          totalEquityAndLiabilities: formatCurrencyHelper(
            equityAndLiabilitiesTotal
          )
        }
      };
    }) ?? {}
  );
}

function getHistoricalFinancialDataFinland(
  data: CreditProcessState
): FinancialTableDataItem[] {
  const { finance } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.FINLAND
    >) ?? {};
  const { financialStatements } = finance?.financialStatements ?? {};
  return (
    financialStatements?.map((itm) => {
      const { currency: currencyData } = itm ?? "";
      const { currency, symbol } = getCurrencyInfo[currencyData];
      const { incomeStatement } = itm ?? {};
      const { currentAssets, nonCurrentAssets, assetsTotal } =
        itm?.balanceSheet?.assets ?? {};
      const { shareholdersEquity, liabilities, equityAndLiabilitiesTotal } =
        itm?.balanceSheet?.liabilitiesAndEquity ?? {};
      const { currentLiabilities, nonCurrentLiabilities } = liabilities ?? {};

      function formatCurrencyHelper(number: number | string) {
        return formatCurrency(number, symbol, currency);
      }

      return {
        key: itm?.closingPeriod?.year ?? "",
        value: {
          /* Income */
          netIncome: formatCurrencyHelper(incomeStatement?.netProfitLoss),
          cashInHand: formatCurrencyHelper(
            currentAssets?.cashAndCashEquivalents
          ),
          turnover: formatCurrencyHelper(incomeStatement?.turnoverTotal),
          resultBeforeTax: formatCurrencyHelper(
            incomeStatement?.operatingResult
          ),
          resultAfterDepreciation: formatCurrencyHelper(
            incomeStatement?.depreciation
          ),
          totalFinancialIncome:
            incomeStatement?.externalInterestIncome == null ||
            incomeStatement?.otherFinancialIncome == null ||
            incomeStatement?.incomeFromShares == null
              ? ""
              : formatCurrencyHelper(
                  incomeStatement.externalInterestIncome +
                    incomeStatement.otherFinancialIncome +
                    incomeStatement.incomeFromShares
                ),
          totalFinancialCosts:
            incomeStatement?.externalInterestExpenses == null ||
            incomeStatement?.otherFinancialExpenses == null ||
            incomeStatement?.impairmentsOfFinancialAssets == null
              ? ""
              : formatCurrencyHelper(
                  incomeStatement?.externalInterestExpenses +
                    incomeStatement?.otherFinancialExpenses +
                    incomeStatement?.impairmentsOfFinancialAssets
                ),
          /* Assets */
          fixedAssets: formatCurrencyHelper(
            nonCurrentAssets?.nonCurrentAssetsTotal
          ),
          intangibleAssets: formatCurrencyHelper(
            nonCurrentAssets?.intangibleAssetsTotal
          ),
          financialAssets: formatCurrencyHelper(
            nonCurrentAssets?.financialNonCurrentAssetsTotal
          ),
          otherFixedAssets: formatCurrencyHelper(
            nonCurrentAssets?.otherFinancialAssets
          ),
          totalCurrentAssets: formatCurrencyHelper(
            currentAssets?.currentAssetsTotal
          ),
          inventories: formatCurrencyHelper(currentAssets?.inventoriesTotal),
          totalReceivables:
            currentAssets?.accountsReceivable == null ||
            currentAssets?.receivableFromGroupAndAssociatedCompanies == null ||
            currentAssets?.otherReceivables == null
              ? ""
              : formatCurrencyHelper(
                  currentAssets?.accountsReceivable +
                    currentAssets?.receivableFromGroupAndAssociatedCompanies +
                    currentAssets?.otherReceivables
                ),
          investments: formatCurrencyHelper(
            currentAssets?.securities // TODO: Is this correct field?
          ),

          otherCurrentAssets: formatCurrencyHelper(
            currentAssets?.otherCurrentAssets
          ),
          totalAssets: formatCurrencyHelper(assetsTotal),

          /* Liabilities & equity */
          shareholderEquity: formatCurrencyHelper(
            shareholdersEquity?.equityTotal
          ),
          retainedEarnings: formatCurrencyHelper(
            shareholdersEquity?.transferredEarnings
          ),
          totalLiabilities:
            liabilities?.nonCurrentLiabilities?.longTermLiabilitiesTotal ==
              null ||
            liabilities?.currentLiabilities?.currentLiabilitiesTotal == null
              ? ""
              : formatCurrencyHelper(
                  liabilities?.nonCurrentLiabilities?.longTermLiabilitiesTotal +
                    liabilities?.currentLiabilities?.currentLiabilitiesTotal
                ),
          longTermLiabilities: formatCurrencyHelper(
            nonCurrentLiabilities?.longTermLiabilitiesTotal
          ),
          otherLongTermLiabilities: formatCurrencyHelper(
            nonCurrentLiabilities?.otherLongTermLiabilities
          ),
          currentLiabilitiesTotal: formatCurrencyHelper(
            currentLiabilities?.currentLiabilitiesTotal
          ),
          totalEquityAndLiabilities: formatCurrencyHelper(
            equityAndLiabilitiesTotal
          )
        }
      };
    }) ?? {}
  );
}
