import { OverviewTableDataItem } from "../../../../types/normalizedData";
import { RowHeaders } from "../../../../types/rowHeaders";
import { Header } from "../Header";

interface OverviewTableProps {
  headersList: RowHeaders[];
  overview: OverviewTableDataItem[];
  tableHeader?: string;
}

export function OverviewTable({
  headersList,
  overview,
  tableHeader
}: OverviewTableProps) {
  const generateTableRows = (
    headers: RowHeaders[],
    overviewData: OverviewTableDataItem[]
  ): JSX.Element[] => {
    return headers.map((header) => {
      const rowData = overviewData.find((item) => item.key === header.key);

      const cellValue = rowData?.value || "N/A";
      const cellComment = rowData?.comment;
      const cellWarning = rowData?.warning;

      return (
        <tr key={header.key} className="bg-white border-b border-gray-300">
          <td className=" bg-white py-2 font-medium text-gray-700 w-48">
            {header.value}
          </td>
          <td className="text-gray-600 text-right w-64">{cellValue}</td>
          {generateCellComment(cellComment)}
          {cellWarning ? generateCellWarning(cellWarning) : null}
        </tr>
      );
    });
  };
  return (
    <div className="">
      {tableHeader && <Header header={tableHeader} />}
      <table className="w-full table-auto">
        <tbody>{generateTableRows(headersList, overview)}</tbody>
      </table>
    </div>
  );
}

export function generateCellComment(
  cellComment: string | undefined
): JSX.Element | null {
  if (!cellComment) {
    return null;
  }
  return (
    <td className="px-4 text-left text-gray-400">
      {cellComment ? cellComment : ""}
    </td>
  );
}

export function generateCellWarning(cellWarning: string): JSX.Element {
  return (
    <td className="px-1 text-left font-semibold text-red-500">{cellWarning}</td>
  );
}
