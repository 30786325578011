import { useState } from "react";
import { TestRunData } from "../../utils/modifications";
import { Icons } from "@flow/icons";
import { Colors } from "@flow/colors";
import { DecisionTestingCard } from "./DecisionTestingCard";
import { ApplicationsCountDisplay } from "./ApplicationsCountDisplay";
import { standarizeString } from "../../utils/standarizeString";

export function DecisionTestingDefaultCollapsible({
  title,
  data,
  flowId
}: {
  title: string;
  data: TestRunData;
  flowId: string;
}) {
  const { dryRun, existing, live, totals } = data?.totalOutcome ?? {};
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b-2">
      <button
        className="w-full h-10 flex flex-row items-center justify-between "
        onClick={toggleCollapse}
      >
        <div className="flex flex-row items-center gap-2 ">
          {title}
          {ApplicationsCountDisplay(
            setIsHovered,
            totals?.numberOfApplications,
            isHovered
          )}
        </div>
        {isOpen ? (
          <Icons.ChevronUp color={Colors.Sea} />
        ) : (
          <Icons.ChevronDown color={Colors.Sea} />
        )}
      </button>
      {isOpen ? (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-5">
            <div className="text-sea-sea">Flow Id:</div>
            {flowId}
          </div>
          <div className="flex flex-row gap-5">
            <div className="text-sea-sea">Country:</div>
            {data?.country ? standarizeString(data?.country) : "N/A"}
          </div>
          <div>
            {(totals?.numberOfApplications ?? 0) > 0 && (
              <div className="grid lg:grid-cols-3 md:grid-cols-2 shrink gap-5">
                <DecisionTestingCard
                  title={"Existing"}
                  data={existing}
                  isExisting={true}
                />
                <DecisionTestingCard title={"Live"} data={live} />
                <DecisionTestingCard title={"Dry Run"} data={dryRun} />
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}
