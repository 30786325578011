import {
  displaySVG,
  findDateString,
  findTimeOfDay
} from "../../utils/findDateString";
import { WidgetCard } from "../widgetCard/WidgetCard";
import { homeConfig } from "../../utils/homeConfig";
import { getNamespaceAndEnv } from "../../utils/namespace";

export function HomePage(props: any) {
  const getNamespace = getNamespaceAndEnv();
  const iconPath = process.env.PUBLIC_URL + "/";

  const date = new Date();
  const dateString = findDateString(date);
  const timeOfDay = findTimeOfDay(date);
  const svgPath = displaySVG(timeOfDay);

  return (
    <div className="h-full w-full min-h-screen overflow-y-scroll bg-gray-100 p-12 flex flex-col gap-1">
      <div className="flex flex-col gap-12">
        <div>
          <p className="text-sm">{dateString}</p>
          <h1 className="text-2xl">
            {`Good ${timeOfDay}, ${props?.user?.name.split(" ")?.[0]}`}
          </h1>
        </div>
        <div className="flex flex-row gap-5">
          <WidgetCard
            imgSrc={`${iconPath}decision-admin.webp`}
            isLast={false}
            alt="Decision Admin picture"
            text={"Decision Admin"}
            url={`${homeConfig?.adminUrl}`}
          ></WidgetCard>
          <WidgetCard
            imgSrc={`${iconPath}digital-architecture.jpeg`}
            alt="Documentation picture"
            isLast={false}
            text={"Documentation"}
            url={`https://docs.${getNamespace.namespace}.elkjop-finance.stacc.${getNamespace.env}/`}
          ></WidgetCard>
        </div>
      </div>
      <div className="h-auto w-full flex flex-row justify-center">
        <div className="h-96 w-96 ">
          <img src={`${iconPath}${svgPath}`} alt="time of day illustrated" />
        </div>
      </div>
    </div>
  );
}
