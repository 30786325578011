import type { TypeApplicant } from "../../types/applicant";
import { Translation } from "../../types/taskProps";
import { CaseSummaryItem } from "./CaseSummaryItem";

type ApplicantProps = {
  applicant: TypeApplicant;
  fixedT: Translation;
};

export function Applicant({ applicant, fixedT }: ApplicantProps) {
  return (
    <div>
      <h2 className="font-semibold pl-1 text-lg mb-3">{fixedT("applicant")}</h2>
      <div className="grid w-full auto-rows-auto gap-1 bg-white px-2 py-1 rounded-md">
        <CaseSummaryItem
          firstLabel={fixedT("full-name")}
          firstValue={`${applicant.firstName} ${applicant.lastName}`}
          secondLabel={fixedT("phone-number")}
          secondValue={
            applicant?.mobilePhoneNumber?.normalizedInternationalPhoneNumber
          }
        />
        <CaseSummaryItem
          firstLabel={fixedT("identity")}
          firstValue={applicant.identity}
          secondLabel={fixedT("email")}
          secondValue={applicant.email}
        />
      </div>
    </div>
  );
}
