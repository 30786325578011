import { Translation } from "../../types/taskProps";
import { CaseSummaryItem } from "./CaseSummaryItem";

type ModificationsCaseSummaryProps = {
  flowData: {
    merchantTransactionReference: string;
    pspPaymentId: string;
    amount: {
      value: number;
      currency: string;
    };
    creditPayment: {
      flowId: string;
    };
  };
  fixedT: Translation;
};

export function ModificationsCaseSummary({
  flowData,
  fixedT
}: ModificationsCaseSummaryProps) {
  const applicationId = flowData?.creditPayment?.flowId;
  const pspPaymentId = flowData?.pspPaymentId;
  return (
    <div
      className={
        "border-line grid gap-4 rounded-md border-solid border-primary-200 bg-primary-50 p-4 shadow items-center break-words text-gray-700 font-medium text-sm"
      }
    >
      {applicationId ? (
        <div className="pl-2 text-base text-blue-400 hover:underline">
          <a
            href={`${window.location.origin}/cases/case/credit-payment/${applicationId}`}
          >
            {`${fixedT("application")}: ${applicationId}`}
          </a>
        </div>
      ) : null}
      <div className="grid w-full auto-rows-auto gap-1 bg-white px-2 py-1 rounded-md">
        <CaseSummaryItem
          firstLabel={fixedT("application-flow-id")}
          firstValue={applicationId}
          secondLabel={fixedT("merchant-transaction-reference")}
          secondValue={flowData?.merchantTransactionReference}
        />
        <CaseSummaryItem
          firstLabel={fixedT("psp-payment-id")}
          firstValue={pspPaymentId}
          secondLabel={fixedT("amount")}
          secondValue={`${flowData?.amount.value} ${flowData?.amount.currency}`}
        />
      </div>
    </div>
  );
}
