import { CreditProcessState } from "../types/creditProcess/state";
import { B2BResponseModel } from "../types/d&b/b2b";
import { COUNTRY_CODE, CountryCode } from "../types/creditProcess/enums";
import { OverviewData, OverviewTableDataItem } from "../types/normalizedData";
import { calculateYearDifference } from "./calculateAge";
import { dateToString } from "./dateToString";
import { formatIdentityNumber } from "./formatIdentityNumber";
import { formatPhoneNumber } from "./formatPhoneNumber";
import { formatCurrency } from "./formatCurrency";
import { getCurrencyInfo } from "./getCurrencyInfo";

export function getOverviewHelper(
  data: CreditProcessState,
  countryCode: CountryCode | undefined
) {
  switch (countryCode) {
    case COUNTRY_CODE.SWEDEN:
      return getOverviewDataSweden(data);
    case COUNTRY_CODE.NORWAY:
      return getOverviewDataNorway(data);
    case COUNTRY_CODE.FINLAND:
      return getOverviewDataFinland(data);
    case COUNTRY_CODE.DENMARK:
      return getOverviewDataDenmark(data);
    default:
      return undefined;
  }
}

function getOverviewDataNorway(data: CreditProcessState): OverviewData {
  const { risk, companyInformation } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.NORWAY
    >) ?? {};
  const { historicalCreditRatings } = risk?.creditRatings ?? {};
  const { currentScore } = risk?.failureScores ?? {};
  const { currency: currencyData } = data?.application?.approvedCredit ?? {};
  const { currency, symbol } = getCurrencyInfo[currencyData ?? ""];

  const historicalData: OverviewTableDataItem[] =
    historicalCreditRatings?.map((itm) => {
      const { year, month, day } = itm?.annualReportDate ?? {};

      const preFormatDate = new Date(year, month, day);

      return {
        key: itm?.annualReportDate?.year ?? "",
        value: itm?.code ?? "-",
        comment: itm?.description ?? "",
        date: preFormatDate
      };
    }) ?? [];
  historicalData.sort((a, b) => b.date!.getTime() - a.date!.getTime());
  function formatCurrencyHelper(number: number | string) {
    return formatCurrency(number, symbol, currency);
  }

  const creditScore: OverviewTableDataItem[] = [
    {
      key: "creditScore",
      value: currentScore?.scoreValue ?? ""
    },
    {
      key: "risk-percentage",
      value: `${parseFloat(currentScore?.riskPercentage).toFixed(2)} %` ?? ""
    }
  ];

  const companyInformationData: OverviewTableDataItem[] = [
    {
      key: "registeredName",
      value: companyInformation?.companyName?.registeredName?.name ?? ""
    },
    {
      key: "industryCode",
      value:
        companyInformation?.industryCodeSet?.sni2007IndustryCodes
          ?.primaryIndustryCode?.code ?? "",
      comment:
        companyInformation?.industryCodeSet?.sni2007IndustryCodes
          ?.primaryIndustryCode?.description ?? " "
    },
    {
      key: "legalForm",
      value: companyInformation?.legalForm?.localCode ?? "",
      comment: companyInformation?.legalForm?.description ?? ""
    },
    {
      key: "status",
      value: companyInformation?.status?.value ?? "",
      comment: companyInformation?.status?.additionalInformation
        ?.registeredVATByMVR
        ? "Registered VAT"
        : "Not registered VAT",
      warning: companyInformation?.status?.additionalInformation?.creditBlocked
        ? "Credit blocked"
        : ""
    },
    {
      key: "establishedDate",
      value:
        companyInformation?.registrationInformation?.establishmentDate?.year ??
        "",
      comment: `Registered date: ${
        dateToString(
          companyInformation?.registrationInformation?.registrationDate?.day,
          companyInformation?.registrationInformation?.registrationDate?.month,
          companyInformation?.registrationInformation?.registrationDate?.year
        ) ?? ""
      }`
    },
    {
      key: "numberOfEmployees",
      value:
        companyInformation?.generalCompanyData?.employeeCount?.toString() ?? ""
    },
    {
      key: "companyAge",
      value:
        calculateYearDifference(
          companyInformation?.registrationInformation?.establishmentDate?.year
        ).toString() ?? ""
    }
  ];

  const decisionData: OverviewTableDataItem[] = [
    {
      key: "approved-credit",
      value: formatCurrencyHelper(
        data?.application?.approvedCredit?.value ?? ""
      )
    },
    {
      key: "credit-desired",
      value: formatCurrencyHelper(data?.application?.desiredCredit?.value ?? "")
    },
    {
      key: "max-approved-credit",
      value: formatCurrencyHelper(
        data?.application?.maxApprovedCredit?.value ?? ""
      )
    },
    {
      key: "existing-credit-limit",
      value: formatCurrencyHelper(
        data?.applicant?.companyApplicant?.existingCreditLimit?.value ?? ""
      )
    },
    {
      key: "remaining-credit-limit",
      value: formatCurrencyHelper(
        data?.applicant?.companyApplicant?.remainingCreditLimit?.value ?? ""
      )
    }
  ];

  const mainApplicantData: OverviewTableDataItem[] = [
    {
      key: "name",
      value: `${data?.applicant?.mainPersonApplicant?.firstName ?? ""} ${
        data?.applicant?.mainPersonApplicant?.lastName ?? ""
      }`
    },
    {
      key: "identity",
      value:
        formatIdentityNumber(data?.applicant?.mainPersonApplicant?.identity) ??
        ""
    },
    {
      key: "phone",
      value:
        formatPhoneNumber(
          data?.applicant?.mainPersonApplicant?.mobilePhoneNumber
            ?.normalizedInternationalPhoneNumber
        ) ?? ""
    },
    {
      key: "email",
      value: data?.applicant?.mainPersonApplicant?.email ?? ""
    }
  ];

  const companyData: OverviewTableDataItem[] = [
    {
      key: "name",
      value: data?.applicant?.companyApplicant?.companyName ?? ""
    },
    {
      key: "organizationNumber",
      value: data?.applicant?.companyApplicant?.companyRegistrationNumber ?? ""
    },
    {
      key: "shareCapital",
      value: `${
        parseInt(
          companyInformation?.capital?.shareCapital?.amount?.amount
        ).toLocaleString() || ""
      } ${companyInformation?.capital?.shareCapital?.amount?.currency || ""}`
    }
  ];

  return {
    companyInformationData,
    decisionData,
    mainApplicantData,
    companyData,
    historicalData,
    creditScore
  };
}

function getOverviewDataSweden(data: CreditProcessState): OverviewData {
  const { risk, companyInformation } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.SWEDEN
    >) ?? {};
  const { historicalCreditRatings } = risk?.creditRatings ?? {};
  const { currentScore } = risk?.failureScores ?? {};
  const { currency: currencyData } = data?.application?.approvedCredit ?? {};
  const { currency, symbol } = getCurrencyInfo[currencyData ?? ""];

  const historicalData: OverviewTableDataItem[] =
    historicalCreditRatings?.map((itm) => {
      const { year, month, day } = itm?.annualReportDate ?? {};

      const preFormatDate = new Date(year, month, day);

      return {
        key: itm?.annualReportDate?.year ?? "",
        value: itm?.code ?? "-",
        comment: itm?.description ?? "",
        date: preFormatDate
      };
    }) ?? [];
  historicalData.sort((a, b) => b.date!.getTime() - a.date!.getTime());

  function formatCurrencyHelper(number: number | string) {
    return formatCurrency(number, symbol, currency);
  }

  const creditScore: OverviewTableDataItem[] = [
    {
      key: "creditScore",
      value: currentScore?.scoreValue ?? ""
    },
    {
      key: "risk-percentage",
      value: `${parseFloat(currentScore?.riskPercentage).toFixed(2)} %` ?? ""
    }
  ];

  const companyInformationData: OverviewTableDataItem[] = [
    {
      key: "registeredName",
      value: companyInformation?.companyName?.registeredName?.name ?? ""
    },
    {
      key: "industryCode",
      value:
        companyInformation?.industryCodeSet?.sni2007IndustryCodes
          ?.primaryIndustryCode?.code ?? "",
      comment:
        companyInformation?.industryCodeSet?.sni2007IndustryCodes
          ?.primaryIndustryCode?.description ?? " "
    },
    {
      key: "legalForm",
      value: companyInformation?.legalForm?.localCode ?? "",
      comment: companyInformation?.legalForm?.description ?? ""
    },
    {
      key: "status",
      value: companyInformation?.status?.value ?? "",
      comment: companyInformation?.generalCompanyData?.registeredInVatList
        ? "Registered VAT"
        : "Not registered VAT",
      warning: "" //companyInformation?.status?.additionalInformation?.creditBlocked
      //   ? "Credit blocked"
      //   : ""
    },
    {
      key: "establishedDate",
      value:
        dateToString(
          companyInformation?.registrationInformation?.foundationDate?.day,
          companyInformation?.registrationInformation?.foundationDate?.month,
          companyInformation?.registrationInformation?.foundationDate?.year
        ) ?? ""
    },
    {
      key: "numberOfEmployees",
      value:
        companyInformation?.generalCompanyData?.employeeCount?.toString() ?? ""
    },
    {
      key: "companyAge",
      value:
        calculateYearDifference(
          companyInformation?.registrationInformation?.foundationDate?.year
        ).toString() ?? ""
    }
  ];

  const decisionData: OverviewTableDataItem[] = [
    {
      key: "approved-credit",
      value: formatCurrencyHelper(
        data?.application?.approvedCredit?.value ?? ""
      )
    },
    {
      key: "credit-desired",
      value: formatCurrencyHelper(data?.application?.desiredCredit?.value ?? "")
    },
    {
      key: "max-approved-credit",
      value: formatCurrencyHelper(
        data?.application?.maxApprovedCredit?.value ?? ""
      )
    },
    {
      key: "existing-credit-limit",
      value: formatCurrencyHelper(
        data?.applicant?.companyApplicant?.existingCreditLimit?.value ?? ""
      )
    },
    {
      key: "remaining-credit-limit",
      value: formatCurrencyHelper(
        data?.applicant?.companyApplicant?.remainingCreditLimit?.value ?? ""
      )
    }
  ];

  const mainApplicantData: OverviewTableDataItem[] = [
    {
      key: "name",
      value: `${data?.applicant?.mainPersonApplicant?.firstName ?? ""} ${
        data?.applicant?.mainPersonApplicant?.lastName ?? ""
      }`
    },
    {
      key: "identity",
      value:
        formatIdentityNumber(data?.applicant?.mainPersonApplicant?.identity) ??
        ""
    },
    {
      key: "phone",
      value:
        formatPhoneNumber(
          data?.applicant?.mainPersonApplicant?.mobilePhoneNumber
            ?.normalizedInternationalPhoneNumber
        ) ?? ""
    },
    {
      key: "email",
      value: data?.applicant?.mainPersonApplicant?.email ?? ""
    }
  ];

  const companyData: OverviewTableDataItem[] = [
    {
      key: "name",
      value: data?.applicant?.companyApplicant?.companyName ?? ""
    },
    {
      key: "organizationNumber",
      value: data?.applicant?.companyApplicant?.companyRegistrationNumber ?? ""
    },
    {
      key: "shareCapital",
      value: formatCurrencyHelper(
        companyInformation?.capital?.shareCapital?.amount?.amount ?? ""
      )
    }
  ];

  return {
    historicalData,
    creditScore,
    companyInformationData,
    decisionData,
    mainApplicantData,
    companyData
  };
}

function getOverviewDataFinland(data: CreditProcessState): OverviewData {
  const { risk, companyInformation } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.FINLAND
    >) ?? {};
  const { historicalCreditRatings } = risk?.creditRatings ?? {};
  const { currentScore } = risk?.failureScores ?? {};
  const { currency: currencyData } = data?.application?.approvedCredit ?? {};
  const { currency, symbol } = getCurrencyInfo[currencyData ?? ""];

  const historicalData: OverviewTableDataItem[] =
    historicalCreditRatings?.map((itm) => {
      const { year, month, day } = itm?.annualReportDate ?? {};

      const preFormatDate = new Date(year, month, day);

      return {
        key: itm?.annualReportDate?.year ?? "",
        value: itm?.code ?? "-",
        comment: itm?.description ?? "",
        date: preFormatDate
      };
    }) ?? [];
  historicalData.sort((a, b) => b.date!.getTime() - a.date!.getTime());

  function formatCurrencyHelper(number: number | string) {
    return formatCurrency(number, symbol, currency);
  }

  const creditScore: OverviewTableDataItem[] = [
    {
      key: "creditScore",
      value: currentScore?.scoreValue ?? ""
    },
    {
      key: "risk-percentage",
      value: `${parseFloat(currentScore?.riskPercentage).toFixed(2)} %` ?? ""
    }
  ];

  const companyInformationData: OverviewTableDataItem[] = [
    {
      key: "registeredName",
      value: companyInformation?.companyName?.registeredName?.name ?? ""
    },
    {
      key: "industryCode",
      value:
        companyInformation?.industryCodeSet?.sni2007IndustryCodes
          ?.primaryIndustryCode?.code ?? "",
      comment:
        companyInformation?.industryCodeSet?.sni2007IndustryCodes
          ?.primaryIndustryCode?.description ?? " "
    },
    {
      key: "legalForm",
      value: companyInformation?.legalForm?.code ?? "",
      comment: companyInformation?.legalForm?.description ?? ""
    },
    {
      key: "status",
      value: companyInformation?.status?.value ?? "",
      comment: companyInformation?.identifiers.vatNumber
        ? "Registered VAT"
        : "Not registered VAT",
      warning: "" //companyInformation?.status?.additionalInformation?.creditBlocked
      //   ? "Credit blocked"
      //   : ""
    },
    {
      key: "establishedDate",
      value:
        dateToString(
          companyInformation?.registrationInformation?.registrationDate?.day,
          companyInformation?.registrationInformation?.registrationDate?.month,
          companyInformation?.registrationInformation?.registrationDate?.year
        ) ?? ""
    },
    {
      key: "numberOfEmployees",
      value: companyInformation?.generalCompanyData?.employeeRange ?? ""
    },
    {
      key: "companyAge",
      value:
        calculateYearDifference(
          companyInformation?.registrationInformation?.registrationDate?.year
        ).toString() ?? ""
    }
  ];

  const decisionData: OverviewTableDataItem[] = [
    {
      key: "approved-credit",
      value: formatCurrencyHelper(
        data?.application?.approvedCredit?.value ?? ""
      )
    },
    {
      key: "credit-desired",
      value: formatCurrencyHelper(data?.application?.desiredCredit?.value ?? "")
    },
    {
      key: "max-approved-credit",
      value: formatCurrencyHelper(
        data?.application?.maxApprovedCredit?.value ?? ""
      )
    },
    {
      key: "existing-credit-limit",
      value: formatCurrencyHelper(
        data?.applicant?.companyApplicant?.existingCreditLimit?.value ?? ""
      )
    },
    {
      key: "remaining-credit-limit",
      value: formatCurrencyHelper(
        data?.applicant?.companyApplicant?.remainingCreditLimit?.value ?? ""
      )
    }
  ];

  const mainApplicantData: OverviewTableDataItem[] = [
    {
      key: "name",
      value: `${data?.applicant?.mainPersonApplicant?.firstName ?? ""} ${
        data?.applicant?.mainPersonApplicant?.lastName ?? ""
      }`
    },
    {
      key: "identity",
      value:
        formatIdentityNumber(data?.applicant?.mainPersonApplicant?.identity) ??
        ""
    },
    {
      key: "phone",
      value:
        formatPhoneNumber(
          data?.applicant?.mainPersonApplicant?.mobilePhoneNumber
            ?.normalizedInternationalPhoneNumber
        ) ?? ""
    },
    {
      key: "email",
      value: data?.applicant?.mainPersonApplicant?.email ?? ""
    }
  ];

  const companyData: OverviewTableDataItem[] = [
    {
      key: "name",
      value: data?.applicant?.companyApplicant?.companyName ?? ""
    },
    {
      key: "organizationNumber",
      value: data?.applicant?.companyApplicant?.companyRegistrationNumber ?? ""
    },
    {
      key: "shareCapital",
      value: formatCurrencyHelper(
        companyInformation?.capital?.shareCapital?.amount?.amount ?? ""
      )
    }
  ];

  return {
    historicalData,
    creditScore,
    companyInformationData,
    decisionData,
    mainApplicantData,
    companyData
  };
}

function getOverviewDataDenmark(data: CreditProcessState): OverviewData {
  const { risk, companyInformation } =
    (data?.raw?.dunAndBradstreet?.companyRiskAndCreditData as B2BResponseModel<
      typeof COUNTRY_CODE.DENMARK
    >) ?? {};
  const { historicalCreditRatings } = risk?.creditRatings ?? {};
  const { currentScore } = risk?.failureScores ?? {};
  const { currency: currencyData } = data?.application?.approvedCredit ?? {};
  const { currency, symbol } = getCurrencyInfo[currencyData ?? ""];

  const historicalData: OverviewTableDataItem[] =
    historicalCreditRatings?.map((itm) => {
      const { year, month, day } = itm?.annualReportDate ?? {};

      const preFormatDate = new Date(year, month, day);

      return {
        key: itm?.annualReportDate?.year ?? "",
        value: itm?.code ?? "-",
        comment: itm?.description ?? "",
        date: preFormatDate
      };
    }) ?? [];
  historicalData.sort((a, b) => b.date!.getTime() - a.date!.getTime());

  function formatCurrencyHelper(number: number | string) {
    return formatCurrency(number, symbol, currency);
  }

  const creditScore: OverviewTableDataItem[] = [
    {
      key: "creditScore",
      value: currentScore?.scoreValue ?? ""
    },
    {
      key: "risk-percentage",
      value: `${parseFloat(currentScore?.riskPercentage).toFixed(2)} %` ?? ""
    }
  ];

  const companyInformationData: OverviewTableDataItem[] = [
    {
      key: "registeredName",
      value: companyInformation?.companyName?.registeredName?.name ?? ""
    },
    {
      key: "industryCode",
      value:
        companyInformation?.industryCodeSet?.sni2007IndustryCodes
          ?.primaryIndustryCode?.code ?? "",
      comment:
        companyInformation?.industryCodeSet?.sni2007IndustryCodes
          ?.primaryIndustryCode?.description ?? " "
    },
    {
      key: "legalForm",
      value: companyInformation?.legalForm?.current?.code ?? "",
      comment: companyInformation?.legalForm?.current?.description ?? ""
    },
    {
      key: "status",
      value: companyInformation?.status?.value ?? "",
      comment: companyInformation?.identifiers.vatNumber
        ? "Registered VAT"
        : "Not registered VAT",
      warning: "" //companyInformation?.status?.additionalInformation?.creditBlocked
      //   ? "Credit blocked"
      //   : ""
    },
    {
      key: "establishedDate",
      value:
        dateToString(
          companyInformation?.registrationInformation?.foundationDate?.day,
          companyInformation?.registrationInformation?.foundationDate?.month,
          companyInformation?.registrationInformation?.foundationDate?.year
        ) ?? ""
    },
    {
      key: "numberOfEmployees",
      value:
        companyInformation?.generalCompanyData?.employeeCount?.toString() ?? ""
    },
    {
      key: "companyAge",
      value:
        calculateYearDifference(
          companyInformation?.registrationInformation?.foundationDate?.year
        ).toString() ?? ""
    }
  ];

  const decisionData: OverviewTableDataItem[] = [
    {
      key: "approved-credit",
      value: formatCurrencyHelper(
        data?.application?.approvedCredit?.value ?? ""
      )
    },
    {
      key: "credit-desired",
      value: formatCurrencyHelper(data?.application?.desiredCredit?.value ?? "")
    },
    {
      key: "max-approved-credit",
      value: formatCurrencyHelper(
        data?.application?.maxApprovedCredit?.value ?? ""
      )
    },
    {
      key: "existing-credit-limit",
      value: formatCurrencyHelper(
        data?.applicant?.companyApplicant?.existingCreditLimit?.value ?? ""
      )
    },
    {
      key: "remaining-credit-limit",
      value: formatCurrencyHelper(
        data?.applicant?.companyApplicant?.remainingCreditLimit?.value ?? ""
      )
    }
  ];

  const mainApplicantData: OverviewTableDataItem[] = [
    {
      key: "name",
      value: `${data?.applicant?.mainPersonApplicant?.firstName ?? ""} ${
        data?.applicant?.mainPersonApplicant?.lastName ?? ""
      }`
    },
    {
      key: "identity",
      value:
        formatIdentityNumber(data?.applicant?.mainPersonApplicant?.identity) ??
        ""
    },
    {
      key: "phone",
      value:
        formatPhoneNumber(
          data?.applicant?.mainPersonApplicant?.mobilePhoneNumber
            ?.normalizedInternationalPhoneNumber
        ) ?? ""
    },
    {
      key: "email",
      value: data?.applicant?.mainPersonApplicant?.email ?? ""
    }
  ];

  const companyData: OverviewTableDataItem[] = [
    {
      key: "name",
      value: data?.applicant?.companyApplicant?.companyName ?? ""
    },
    {
      key: "organizationNumber",
      value: data?.applicant?.companyApplicant?.companyRegistrationNumber ?? ""
    },
    {
      key: "shareCapital",
      value: formatCurrencyHelper(
        companyInformation?.capital?.shareCapital?.amount?.amount ?? ""
      )
    }
  ];

  return {
    historicalData,
    creditScore,
    companyInformationData,
    decisionData,
    mainApplicantData,
    companyData
  };
}
