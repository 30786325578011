import { FlowContext } from "../../hooks/useFlowContext";
import { TaskProps } from "../../types/taskProps";
import { Decision } from "./components/sanctions/Decision";
import { DecisionPanel } from "./components/manualHandling/DecisionPanel";
import { Layout } from "./components/manualHandling/Layout";
import { Sanctions } from "./components/manualHandling/Sanctions";

export function SanctionsHandling(props: TaskProps) {
  return (
    <FlowContext.Provider value={props}>
      <Layout>
        <Sanctions {...props} />
        <DecisionPanel>
          <Decision props={props} />
        </DecisionPanel>
      </Layout>
    </FlowContext.Provider>
  );
}
