import { EntityMatch } from "../../../../types/creditProcess/opensanctions/response";
import { ButtonMoreInfo } from "../ButtonMoreInfo";
import { Header } from "../Header";
import { SanctionsDisplayTopics } from "./SanctionsDisplayTopics";
import { MultipleValuesRow } from "./MultipleValuesRow";
import { NoRemarks } from "../NoRemarks";
import { useFlowContext } from "../../../../hooks/useFlowContext";

export function SanctionsApplicant({ data }: { data: EntityMatch }) {
  const { t } = useFlowContext();
  if (!data) {
    return <NoRemarks label={t("no-applicant-sanctions")} />;
  }
  const { match } = data;
  const { topics } = match?.properties ?? [];
  const { id } = match ?? {};
  const name = match?.caption;
  return (
    <div className="flex flex-col gap-5">
      <div className="border-b text-xl">
        <Header header="Applicant" />
      </div>
      {topics && <SanctionsDisplayTopics topics={topics} />}
      <div className="flex flex-col gap-5">
        <MultipleValuesRow
          data={[
            { name: "Name", value: name },
            { name: "Birth date", value: match?.properties?.birthDate?.[0] },
            { name: "Keywords", value: match?.properties?.keywords?.[0] },
            {
              name: "First seen",
              value: (match?.first_seen as string).split("T")?.[0]
            },
            {
              name: "Last changed",
              value: (match?.last_change as string).split("T")?.[0]
            }
          ]}
        />
      </div>

      {id && (
        <ButtonMoreInfo url={`https://www.opensanctions.org/entities/${id}`} />
      )}
    </div>
  );
}
