import { SecondaryButton } from "@flow/buttons";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useFormConnect } from "../../hooks/useFormConnect";
import { SearchForm } from "./index";

export function DecisionTestingSearchForm({
  methods,
  onSubmit
}: {
  methods: UseFormReturn<SearchForm>;
  onSubmit: any;
}) {
  return (
    <div>
      <div className="w-full">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-2">
              <h3>Check previous result</h3>
              <div className="flex flex-col md:flex-row md:items-end justify-between gap-5">
                <Search id="flowId" />
              </div>
              <div className="items-start">
                <SecondaryButton type="submit">{"Search"}</SecondaryButton>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

function Search({ id }: { id: string }) {
  const { ref, error, value, ...props } = useFormConnect(id);
  return (
    <div>
      <input
        {...props}
        type="text"
        defaultValue={value}
        className="flex h-10 w-64 rounded-md border bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        placeholder="Dry run Id"
      />
      {error && <span className="block text-red-500">{error}</span>}
    </div>
  );
}
