import { useQuery } from "@tanstack/react-query";
import { TaskProps } from "../../types/taskProps";
import { Card } from "../Card";
import { TableIcon } from "../icons/Table";
import { DMNViewer } from "./DMNViewer";
import { fetchDecisionsByHash } from "../../utils/decisions";
import { CenteredSpinner } from "@flow/icons";

export function Decisions(props: TaskProps) {
  const { flow } = props;
  const { flowId } = flow;
  const { data, isFetching } = useQuery({
    queryKey: ["decisions", flowId],
    queryFn: async () => {
      const values = Object.values(flow.data?.decisions ?? {});
      const flattenedValues = values.reduce((acc, val) => {
        return acc.concat(val);
      }, []);
      return Promise.all(
        flattenedValues.map(async (item: any) => {
          const xml = await fetchDecisionsByHash(
            item?.decision?.metaData?.layerDigest,
            item?.decision?.metaData?.tablename
          );
          const ruleIds = item?.decision?.ruleIds;
          return { ...item, xml, ruleIds };
        })
      );
    }
  });
  if (isFetching) return <CenteredSpinner />;
  return (
    <div className="flex flex-col gap-4 mx-auto">
      {data?.map((item, index) => {
        return (
          <Card key={index}>
            <Card.Header className="bg-primary-100 text-primary-800 flex items-center">
              <TableIcon width={18} height={18} />
              <span>{item?.decision?.metaData?.tablename}</span>
            </Card.Header>
            <Card.Content horizontalPadding={false} className="p-4">
              <DMNViewer dmnXML={item?.xml} ruleIds={item?.ruleIds} />
            </Card.Content>
          </Card>
        );
      })}
    </div>
  );
}
