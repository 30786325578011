import { Applicant } from "./Applicant";
import { Session } from "./Session";
import { Application } from "./Application";
import type { TypeApplicantData } from "../../types/applicant";
import type { TypeSession } from "../../types/session";
import type { TypeApplication } from "../../types/application";
import type { FinancialProviders } from "../../types/financialProviders";
import { Modifications } from "./Modifications";
import { mapBankId } from "../../functions/mapBankId";
import { Errors } from "./Errors";
import { Error } from "../../types/errors";
import { useEffect, useRef } from "react";
import QRCode from "qrcode";
import { Translation } from "../../types/taskProps";
import { Storage } from "../../types/storage";

type CreditPaymentCaseSummaryProps = {
  flowData: {
    applicant: TypeApplicantData;
    session: TypeSession;
    application: TypeApplication;
    bankId: FinancialProviders;
    errors: Error[];
    storage: Storage;
    referenceId: string;
    signicat: any;
  };
  flowId: string;
  fixedT: Translation;
};

export function CreditPaymentCaseSummary({
  flowData,
  flowId,
  fixedT
}: CreditPaymentCaseSummaryProps) {
  const applicant = flowData?.applicant?.applicants?.[0];
  const session = flowData?.session;
  const application = flowData?.application;
  const bankId = flowData.bankId;
  const errors = flowData?.errors;
  const isDevOrTestEnv = !window.location.origin.includes("prod");
  const canvasRef = useRef(null);
  const qrData = isDevOrTestEnv
    ? flowData?.signicat?.session?.status?.idpData?.qrData
    : undefined;
  useEffect(() => {
    if (!qrData) return;
    const canvas = canvasRef.current;
    QRCode.toCanvas(canvas, qrData);
  }, [qrData]);
  return (
      <div>
      {isDevOrTestEnv && qrData ? (
          <div>
              <h1 className="font-bold text-xl">Signicat BankID QR Code</h1>
              <canvas ref={canvasRef}></canvas>
          </div>
      ) : null}
        <div
          className={
            "border-line grid gap-4 rounded-md border-solid border-primary-200 bg-primary-50 p-4 shadow items-center break-words text-neutral-900 font-medium text-sm"
          }
        >
          <div className="font-medium text-2xl ">{mapBankId(bankId)}</div>
          {applicant ? <Applicant applicant={applicant} fixedT={fixedT} /> : null}
          {session ? <Session session={session} fixedT={fixedT} /> : null}
          {application ? (
            <Application application={application} fixedT={fixedT} />
          ) : null}
          <Modifications
            flowId={flowId}
            pspPaymentId={flowData?.referenceId}
            fixedT={fixedT}
          />
          {errors && errors?.length > 0 && (
            <Errors flowData={flowData} fixedT={fixedT} />
          )}
        </div>
      </div>
  );
}
