export function standarizeString(inputString: string): string {
  if (!inputString || inputString.length === 0) {
    return inputString;
  }

  const firstLetter = inputString.charAt(0).toUpperCase();
  const restOfTheString = inputString.slice(1).toLowerCase();

  return firstLetter + restOfTheString;
}
