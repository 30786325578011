import { PROCESSES } from "../../types/processes";
import { CreditPaymentCaseSummary } from "./CreditPaymentCaseSummary";
import { NotificationsCaseSummary } from "./NotificationsCaseSummary";
import { CreditProcessCaseSummary } from "./CreditProcessCaseSummary";
import { Translation } from "../../types/taskProps";
import { CreditProcessState } from "../../types/creditProcess/state";
import { ModificationsCaseSummary } from "./ModificationsCaseSummary";

export type CaseSummaryProps<T = any> = {
  caseData: T;
  flowDefinitionId: string;
  t: Translation;
  map?: (flow: any) => any;
};

export function CaseSummary({
  caseData,
  flowDefinitionId,
  t
}: CaseSummaryProps) {
  const flowData = caseData.data;
  const flowId = caseData?.flowId;

  switch (flowDefinitionId) {
    case PROCESSES.CREDIT_PAYMENT:
      return (
        <CreditPaymentCaseSummary
          flowData={flowData}
          flowId={flowId}
          fixedT={t}
        />
      );
    case PROCESSES.NOTIFICATIONS:
      return <NotificationsCaseSummary flowData={flowData} fixedT={t} />;
    case PROCESSES.CREDIT_PROCESS:
      return (
        <CreditProcessCaseSummary
          flowData={flowData as CreditProcessState}
          fixedT={t}
        />
      );
    case PROCESSES.CAPTURE:
    case PROCESSES.CANCEL:
    case PROCESSES.REFUND:
      return <ModificationsCaseSummary flowData={flowData} fixedT={t} />;

    default:
      return null;
  }
}
