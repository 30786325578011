import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { ChartLineData } from "../../../../utils/chartLineData";
import { ContentType } from "recharts/types/component/Tooltip";
import { NameType } from "recharts/types/component/DefaultTooltipContent";
import { CustomTooltipProps } from "../../../../types/tooltipProps";

type ChartLineResultProps = {
  charData: ChartLineData[];
};

export function ChartLine({ charData }: ChartLineResultProps) {
  if (!charData) return null;

  return (
    <div className="grid gap-2">
      <h4 className="text-center text-sea-sea text-base">
        Result before taxes
      </h4>
      <div className="w-full h-full">
        <ResponsiveContainer>
          <ComposedChart
            data={charData}
            margin={{
              top: 10,
              bottom: 5,
              right: 40,
              left: 40
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" padding={{ left: 10, right: 10 }} />
            <YAxis tickFormatter={(value) => `${value.toLocaleString()}`} />

            <Area
              type="monotone"
              dataKey="resultBeforeTax"
              fill="#467EFF33"
              tooltipType="none"
            />
            <Line
              type="monotone"
              dataKey="resultBeforeTax"
              stroke="#467EFF"
              strokeWidth={2}
            />
            <Tooltip content={CustomTooltip as ContentType<any, NameType>} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export const CustomTooltip = ({
  active,
  payload,
  label
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const lineData = payload.find((data) => data.dataKey === "resultBeforeTax");
    if (!lineData) return null;
    const number = Number(lineData.value).toLocaleString();

    return (
      <div className="py-4 px-2 bg-white border-2 rounded-md">
        {lineData && (
          <div>
            <div className="">{label}</div>
            <div className="text-black">{number}</div>
          </div>
        )}
      </div>
    );
  }

  return <div>No data</div>;
};
