import { DecisionTesting } from "../components/DecisionTesting";
import { Icons } from "@flow/icons";
import { TypeRole } from "../types/role";
import { isAdmin } from "../functions/isRole";
import { HomePage } from "../components/Home/HomePage";
import { Filter } from "../components/icons/Filter";
import { Storage } from "../components/storage/Storage";

type CustomRoute = {
  path: string;
  component: React.ElementType;
  navigation?: {
    title: string;
    icon: React.ElementType;
  };
};

const adminCustomRoutes: CustomRoute[] = [
  {
    path: "/storage",
    component: Storage,
    navigation: {
      title: "Storage",
      icon: Filter
    }
  },
  {
    path: "/decisionTesting",
    component: DecisionTesting,
    navigation: {
      title: "decisionTesting",
      icon: Icons.Auditlog
    }
  },
  {
    path: "",
    component: HomePage
  }
];

const notAdminCustomRoutes: CustomRoute[] = [
  {
    path: "/storage",
    component: Storage,
    navigation: {
      title: "Storage",
      icon: Filter
    }
  },
  {
    path: "",
    component: HomePage
  }
];

export function getCustomRoutes(role: TypeRole): CustomRoute[] {
  if (isAdmin(role)) {
    return adminCustomRoutes;
  }
  return notAdminCustomRoutes;
}
