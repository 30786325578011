import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { ChartBarData } from "../../../../utils/chartBarData";
import { ContentType } from "recharts/types/component/Tooltip";
import { NameType } from "recharts/types/component/DefaultTooltipContent";
import { CustomTooltipProps } from "../../../../types/tooltipProps";

type ChartBarOperatingIncomeProps = {
  charData: ChartBarData[];
};

export function ChartBar({ charData }: ChartBarOperatingIncomeProps) {
  if (!charData) return null;

  return (
    <div className="grid gap-2">
      <h4 className="text-center text-sea-sea text-base">
        Total operating income
      </h4>
      <div className="w-full h-full">
        <ResponsiveContainer>
          <BarChart
            data={charData}
            margin={{
              top: 5,
              bottom: 5,
              right: 40,
              left: 40
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" padding={{ left: 10, right: 10 }} />
            <YAxis tickFormatter={(value) => `${value.toLocaleString()}`} />
            <Tooltip content={CustomTooltip as ContentType<any, NameType>} />

            <Bar
              dataKey="totalOperatingIncome"
              fill="#FFBB16"
              activeBar={<Rectangle fill="#E09F00" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export const CustomTooltip = ({
  active,
  payload,
  label
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const lineData = payload.find(
      (data) => data.dataKey === "totalOperatingIncome"
    );
    if (!lineData) return null;
    const number = Number(lineData.value).toLocaleString();

    return (
      <div className="py-4 px-2 bg-white border-2 rounded-md">
        {lineData && (
          <div>
            <div className="">{label}</div>
            <div className="text-black">{number}</div>
          </div>
        )}
      </div>
    );
  }

  return <div>No data</div>;
};
