import { useState } from "react";
import { ApplicationsCountDisplay } from "./ApplicationsCountDisplay";
import { Skeleton } from "./Skeleton";

export function NumberOfApplicationsUsed({
  count
}: {
  count: number | undefined;
}) {
  const [isHovered, setIsHovered] = useState(false);

  if (!count) {
    return (
      <div className="">
        <Skeleton className="w-5 h-5" />
      </div>
    );
  }

  return (
    <button>{ApplicationsCountDisplay(setIsHovered, count, isHovered)}</button>
  );
}
