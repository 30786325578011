import { TaskProps } from "../../../../types/taskProps";
import { OverviewHistoricalCollapsable } from "./OverviewHistoricalCollapsable";
import { RowHeaders } from "../../../../types/rowHeaders";
import { getOverviewHelper } from "../../../../utils/overviewData";
import { getCountryCode } from "../../../../utils/countryNameToCode";
import { OverviewTable } from "./OverviewTable";
import { OverviewScoreCollapsable } from "./OverviewScoreCollapsable";
import { CreditProcessCaseSummary } from "../../../CaseSummary/CreditProcessCaseSummary";

export function Overview(props: TaskProps) {
  const { flow, t } = props;
  const { data } = flow;
  const { country } = data?.applicant;

  const countryCode = getCountryCode(country);
  const overviewData = getOverviewHelper(data, countryCode);

  const companyInfoHeaders: RowHeaders[] = [
    { key: "registeredName", value: t("registeredName") },
    { key: "industryCode", value: t("industryCode") },
    { key: "legalForm", value: t("legalForm") },
    { key: "status", value: t("status") },
    { key: "establishedDate", value: t("establishedDate") },
    { key: "numberOfEmployees", value: t("numberOfEmployees") },
    { key: "companyAge", value: t("companyAge") }
  ];

  return (
    <div className="overflow-auto pt-5 pb-10">
      <div className="flex flex-col gap-10">
        <CreditProcessCaseSummary fixedT={t} flowData={data} />
        <div className="flex flex-col gap-10">
          <div>
            <OverviewHistoricalCollapsable
              overviewHistorical={overviewData!.historicalData}
              t={t}
              tableHeader={t("risk")}
            />
            <OverviewScoreCollapsable
              overviewHistorical={overviewData!.creditScore}
              t={t}
            />
          </div>
          <div className="">
            <OverviewTable
              headersList={companyInfoHeaders}
              overview={overviewData!.companyInformationData}
              tableHeader={t("companyInformation")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
