export function formatPhoneNumber(phoneNumber: string) {
  // Remove any non-digit characters from the input
  const digitsOnly = phoneNumber.replace(/\D/g, "");

  // Check if the input is empty or not a valid phone number
  if (!digitsOnly || digitsOnly.length < 2) {
    return phoneNumber; // Return the original input if it's empty or not valid
  }

  // Extract the country code and the rest of the number
  const countryCode = digitsOnly.substring(0, 2);
  const restOfNumber = digitsOnly.substring(2);

  // Use regular expressions to format the rest of the number with spaces
  const formattedNumber = restOfNumber.replace(
    /(\d{3})(\d{2})(\d{2})/,
    "$1 $2 $3"
  );

  // Combine the country code and the formatted rest of the number
  const formattedPhoneNumber = `+${countryCode} ${formattedNumber}`;

  return formattedPhoneNumber;
}
