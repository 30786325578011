export enum MODIFICATION_STATUS {
  NOT_USED = "NOT_USED",
  USED = "USED",
  ERROR = "ERROR"
}

type Modification = {
  amount: number;
  id: string;
  merchantTransactionReference: string;
  status: MODIFICATION_STATUS;
  type: "CAPTURE" | "REFUND" | "CANCEL";
  uniqueRequestId: string;
  modificationFlowId: string;
};

type Event = {
  Amount: number;
  PspName: string;
  Success: boolean;
  Currency: string;
  ErrorCode: string;
  EventType: string;
  Timestamp: string;
  MerchantId: string;
  ErrorMessage: string;
  PspErrorCode: string;
  PspEventType: string;
  PspPaymentId: string;
  PspTimestamp: string;
  SourceSystem: string;
  PaymentMethod: string;
  PspTransactionId: string;
  MerchantTransactionReference: string;
};

export type TypeModifications = {
  id: string;
  flowId: string;
  referenceId: string;
  amount: number;
  currency: string;
  merchantTransactionReference: string;
  status: "FULLY_CAPTURED" | string;
  authorize?: {
    amount: number;
    status: string;
  };
  pspPaymentId?: string;
  merchantId?: string;
  bankId?: string;
  sellerId?: string;
  capture?: Modification[];
  refunds?: Modification[];
  cancel?: Modification[];
  events: Event[];
};
