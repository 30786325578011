export function calculateYearDifference(
  establishmentYear: number | undefined | null
): number | string {
  if (establishmentYear !== undefined && establishmentYear !== null) {
    const currentYear = new Date().getFullYear();
    const yearDifference = currentYear - establishmentYear;
    return yearDifference;
  } else {
    return "";
  }
}
