import { Link } from "react-router-dom";
import { QueueItemProps } from "./QueueItem";
import { format, parseISO } from "date-fns";

export function CreditPaymentQueueCard({ path, flow }: QueueItemProps) {
  const {
    flowId,
    flowDefinitionId,
    createdAt,
    referenceId,
    referenceName,
    data
  } = flow;
  const applicant = data?.applicant?.companyApplicant?.companyName;
  const createdAtDate = format(parseISO(createdAt), "dd.MM.yyyy");

  const isActive =
    window?.location?.pathname ===
    `/inbox/${path}/${flowDefinitionId}/${flowId}`;

  return (
    <Link
      aria-current={"page"}
      to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
      className={`flex flex-col px-3 py-2 text-xs  ${
        isActive
          ? "border-l-4 border-primary-800 bg-primary-100"
          : "border-l-4 border-opacity-0 bg-primary-50 shadow"
      }`}
    >
      <div>
        <span>
          {referenceName} - {referenceId}
        </span>
        <p className="m-0 py-2 text-base font-medium">{applicant}</p>
      </div>
      <div>{createdAtDate}</div>
    </Link>
  );
}
